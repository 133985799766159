import React from "react";
import { fetchAllProductsAvailable } from 'ducks/products';
import { WithAll } from "components";
import ProductsTable from './ProductsTable';
import get from 'lodash/get';

const ProductsSelector = (({ data, refresh, onSelected, selected, showAvailable, children, showAll, orderBy }) => {
    const rowsPerPage = showAll ? data.length : undefined
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                {selected && <h3>{`${get(selected, 'code')} - ${get(selected, 'name')}`}</h3>}
                <ProductsTable
                    orderBy={orderBy}
                    order='asc'
                    filter={{ showAvailable }}
                    rowsPerPage={rowsPerPage}
                    onRefresh={refresh}
                    fixed={false}
                    data={data}
                    handleItemSelected={onSelected}
                    showStatus
                />
            </div>
            {children}
        </div>
    )
});

export default WithAll('products', fetchAllProductsAvailable)(ProductsSelector);