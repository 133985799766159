import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, TextField, MenuItem } from "@material-ui/core";

import { RegularCard, Button } from "components";

import { get, toLower } from "lodash";
import FormNumericInput from "../../../components/CustomInput/FormNumericInput";
import moment from "moment";
import { fetchAllResources } from "ducks/resources";
import { fetchAllProductsAvailable } from "ducks/products";
import VirtualizedSelect from "react-virtualized-select";
import { InlineDatePicker } from "material-ui-pickers";
import {
  fetchAvailableAdjustmentTimes,
  fetchAvailableDeliveryTimes,
} from "ducks/agenda";
import { fetchSingleResource, putResource } from "../../../ducks/resources";
import { Resources, roles } from "utils/variables";

class Edit extends Component {
  state = {
    tabPos: 0,
    contractUri: "",
    client: { name: "", cpf: "" },
    event: { id: "", name: "", date: moment() },
    product: { code: "", name: "", rentalValue: "" },
    adjustmentDate: null,
    adjustmentTime: "",
    deliveryDate: null,
    deliveryTime: "",
    discount: 0,
    additionalValue: 0,
    paidValue: 0,
    paymentForm: 0,
    seller: { fullName: "" },
    notes: "",
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    id &&
      this.props.getRental(id).then((r) => {
        get(r, "type") === "GET_SINGLE_FAILURE" && this.props.history.goBack();
        this.setState({ ...this.props.selectedRental }, () => {
          this.state.adjustmentDate &&
            this.props.getAvailableAdjustmentTimes(this.state.adjustmentDate);
          this.state.deliveryDate &&
            this.props.getAvailableDeliveryTimes(this.state.deliveryDate);
          this.props.getClients();
          this.props.getEvents();
          this.props.getProducts(this.state.event.id);
          this.props.getSellers();
        });
      });
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      get(prevState, "event.id") !== this.state.event.id &&
      get(prevState, "event.id") === ""
    )
      this.props.getProducts(this.state.event.id);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.putRental(this.state).then((r) => {
      if (get(r, "response.success")) {
        const id = get(r, "response.data.id");
        id && this.props.history.push(`/rentals/${id}`);
      }
    });
  };

  handleChange = (event) => {
    const { id, name, value } = event.target;

    this.setState({
      [id || name]: value,
    });
  };

  handleDateChange = (field, date) => {
    this.setState({ [field]: date }, () => {
      if (field === "adjustmentDate")
        this.props
          .getAvailableAdjustmentTimes(date.format())
          .then(() => this.setState({ adjustmentTime: "" }));

      if (field === "deliveryDate")
        this.props
          .getAvailableDeliveryTimes(date.format())
          .then(() => this.setState({ deliveryTime: "" }));
    });
  };

  handleEventChange = (event) => {
    this.props
      .getProducts(event.id)
      .then(() => this.setState({ event, eventId: event.id }));
  };

  render() {
    const {
      client,
      event,
      product,
      adjustmentDate,
      deliveryDate,
      discount,
      seller,
      additionalValue,
      adjustmentTime,
      deliveryTime,
      notes,
    } = this.state;
    const {
      clients,
      events,
      products,
      sellers,
      availableAdjustmentTimes,
      availableDeliveryTimes,
    } = this.props;
    const title = "Editar Locação";
    const subtitle = `Você está editando os dados da locação selecionada.`;

    return (
      <RegularCard cardTitle={title} cardSubtitle={subtitle}>
        <span onClick={this.props.handleClose}>Voltar Para Lista</span>

        <form onSubmit={this.handleSubmit}>
          <Grid container direction="column" alignItems="center">
            <Grid container item direction="column" md={6} spacing={24}>
              <Grid item>
                <VirtualizedSelect
                  options={clients}
                  onChange={(client) =>
                    this.setState({ client, clientId: client.id })
                  }
                  value={client}
                  valueRenderer={(option) => `${option.name} - ${option.cpf}`}
                  optionRenderer={({ option, style, key, selectValue }) => (
                    <div
                      onClick={() => selectValue(option)}
                      key={key}
                      style={style}
                    >{`${option.name} - ${option.cpf}`}</div>
                  )}
                  filterOptions={(options, filterString) =>
                    options.filter(
                      (client) =>
                        get(client, "name", "")
                          .toLowerCase()
                          .trim()
                          .includes(filterString.toLowerCase().trim()) ||
                        get(client, "cpf", "")
                          .toLowerCase()
                          .trim()
                          .includes(filterString.toLowerCase().trim())
                    )
                  }
                />
              </Grid>

              <Grid item>
                <VirtualizedSelect
                  options={events}
                  onChange={this.handleEventChange}
                  value={event}
                  valueRenderer={(event) =>
                    `${event.name} - ${moment(get(event, "date")).format("LL")}`
                  }
                  optionRenderer={({ option, style, key, selectValue }) => (
                    <div
                      onClick={() => selectValue(option)}
                      key={key}
                      style={style}
                    >
                      {`${option.name} - ${moment(get(option, "date")).format(
                        "LL"
                      )}`}
                    </div>
                  )}
                  filterOptions={(options, filterString) =>
                    options.filter((event) =>
                      get(event, "name", "")
                        .toLowerCase()
                        .trim()
                        .includes(filterString.toLowerCase().trim())
                    )
                  }
                />
              </Grid>
              <Grid item>
                <VirtualizedSelect
                  options={products}
                  onChange={(product) =>
                    this.setState({ product, productId: product.id })
                  }
                  value={product}
                  noResultsText="Nenhum item disponível."
                  valueRenderer={(product) =>
                    `${product.code} - ${product.name}`
                  }
                  optionRenderer={({ option, style, key, selectValue }) => (
                    <div
                      onClick={() => selectValue(option)}
                      key={key}
                      style={style}
                    >
                      {`${option.code} - ${option.name}`}
                    </div>
                  )}
                  filterOptions={(options, filterString) =>
                    options.filter(
                      (product) =>
                        toLower(get(product, "code", ""))
                          .trim()
                          .includes(filterString.toLowerCase().trim()) ||
                        toLower(get(product, "name", ""))
                          .trim()
                          .includes(filterString.toLowerCase().trim())
                    )
                  }
                />
              </Grid>

              <Grid item>
                <InlineDatePicker
                  keyboard
                  clearable
                  variant="outlined"
                  margin="normal"
                  label="Data Ajuste"
                  onChange={(date) =>
                    this.handleDateChange("adjustmentDate", date)
                  }
                  value={adjustmentDate}
                  format="DD/MM/YYYY"
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />

                <TextField
                  id="adjustmentTime"
                  name="adjustmentTime"
                  select
                  label="Hora Ajuste"
                  value={adjustmentTime}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  style={{ minWidth: "200px" }}
                  disabled={
                    !Array.isArray(availableAdjustmentTimes) ||
                    !availableAdjustmentTimes.length
                  }
                  helperText={
                    !adjustmentDate &&
                    "Selecione a data do ajuste para ver os horários disponíveis"
                  }
                >
                  {availableAdjustmentTimes.map((option) => (
                    <MenuItem
                      key={option.time}
                      value={option.time}
                      disabled={!option.available}
                      style={{
                        backgroundColor: !option.available && "#ffb2b2",
                      }}
                    >
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item>
                <InlineDatePicker
                  keyboard
                  clearable
                  variant="outlined"
                  margin="normal"
                  label="Data Entrega"
                  onChange={(date) =>
                    this.handleDateChange("deliveryDate", date)
                  }
                  value={deliveryDate}
                  format="DD/MM/YYYY"
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />

                <TextField
                  id="deliveryTime"
                  name="deliveryTime"
                  select
                  label="Hora Entrega"
                  value={deliveryTime}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  style={{ minWidth: "200px" }}
                  disabled={
                    !Array.isArray(availableDeliveryTimes) ||
                    !availableDeliveryTimes.length
                  }
                  helperText={
                    !deliveryDate &&
                    "Selecione a data da entrega para ver os horários disponíveis"
                  }
                >
                  {availableDeliveryTimes.map((option) => (
                    <MenuItem
                      key={option.time}
                      value={option.time}
                      disabled={!option.available}
                      style={{
                        backgroundColor: !option.available && "#ffb2b2",
                      }}
                    >
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <FormNumericInput
                label="Valor Total"
                id="rentalValue"
                value={get(product, "rentalValue")}
                disabled
              />

              <FormNumericInput
                label="Valor Desconto"
                id="discount"
                onChange={this.handleChange}
                value={discount}
              />

              <FormNumericInput
                label="Valor Adicional"
                id="additionalValue"
                onChange={this.handleChange}
                value={additionalValue}
              />

              <TextField
                fullWidth={true}
                id="notes"
                label="Observações"
                value={notes}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />

              <Grid item>
                {this.props.userRole === roles.Admin && (
                  <VirtualizedSelect
                    options={sellers}
                    onChange={(seller) =>
                      this.setState({ seller, sellerId: seller.id })
                    }
                    value={seller}
                    noResultsText="Nenhum item disponível."
                    valueRenderer={(seller) => `${seller.fullName}`}
                    labelKey="fullName"
                    filterOptions={(options, filterString) =>
                      options.filter((seller) =>
                        toLower(get(seller, "fullName", ""))
                          .trim()
                          .includes(filterString.toLowerCase().trim())
                      )
                    }
                  />
                )}
              </Grid>
            </Grid>

            <Button color="primary" type="submit">
              Salvar Locação
            </Button>
          </Grid>
        </form>
      </RegularCard>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userRole,
  selectedRental: state.resources.selectedResource,
  sellers: state.resources.sellers,
  clients: state.resources.clients,
  events: state.resources.events,
  products: state.resources.products,
  availableAdjustmentTimes: state.resources.availableAdjustmentTimes,
  availableDeliveryTimes: state.resources.availableDeliveryTimes,
});

function mapDispatchToProps(dispatch) {
  return {
    getRental: (id) => dispatch(fetchSingleResource(Resources.Rentals, id)),
    getSellers: () => dispatch(fetchAllResources(Resources.Sellers)),
    getClients: () => dispatch(fetchAllResources(Resources.Clients)),
    getEvents: () => dispatch(fetchAllResources(Resources.Events)),
    getProducts: (eventId) => dispatch(fetchAllProductsAvailable(eventId)),
    putRental: (rental) =>
      dispatch(putResource(Resources.Rentals, rental.id, rental)),
    getAvailableAdjustmentTimes: (date) =>
      dispatch(fetchAvailableAdjustmentTimes(date)),
    getAvailableDeliveryTimes: (date) =>
      dispatch(fetchAvailableDeliveryTimes(date)),
  };
}

Edit.defaultProps = {
  sellers: [],
  clients: [],
  events: [],
  products: [],
  availableAdjustmentTimes: [],
  availableDeliveryTimes: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit));
