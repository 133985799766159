import React from "react";
import { fetchAllRentals } from 'ducks/rentals';
import { WithAll } from "components";
import RentalsTable from "./RentalsTable";
import { Grid } from "@material-ui/core";

const RentalsReferenceTable = (({ data, refresh, onSelected, emptyMessage }) => {
    return (
        <Grid container direction='column'>
            <RentalsTable onRefresh={refresh} fixed={data.length > 10} data={data} handleItemSelected={onSelected} emptyMessage={emptyMessage} />
        </Grid>
    )
});

export default WithAll('rentals', fetchAllRentals)(RentalsReferenceTable);