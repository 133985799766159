import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    withMobileDialog,
    Select,
    MenuItem,
    TextField,
    Grid
} from "@material-ui/core";

import {
    Button,
} from "components";

import { putRental } from 'ducks/rentals';
import { RentalStatus } from '../../../utils';
import get from 'lodash/get'
import { fetchAvailableAdjustmentTimes, fetchAvailableDeliveryTimes } from 'ducks/agenda';
import { InlineDatePicker } from 'material-ui-pickers';

class StatusChangeDialog extends Component {
    state = {
        newStatus: get(this.props, 'selectedRental.status'),
        adjustmentDate: get(this.props, 'selectedRental.adjustmentDate') || null,
        adjustmentTime: get(this.props, 'selectedRental.adjustmentTime') || '',
        deliveryDate: get(this.props, 'selectedRental.deliveryDate') || null,
        deliveryTime: get(this.props, 'selectedRental.deliveryTime') || '',
    }

    componentDidMount() {
        this.props.getAvailableAdjustmentTimes(get(this.props, 'selectedRental.adjustmentDate'));
        this.props.getAvailableDeliveryTimes(get(this.props, 'selectedRental.deliveryDate'));
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ newStatus: get(this.props, 'selectedRental.status') })
        this.props.onClose();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let rentalUpdated;

        if (this.state.newStatus === get(this.props, 'selectedRental.status'))
            return;

        switch (this.state.newStatus) {
            case 1:
                rentalUpdated = { ...this.props.selectedRental, status: this.state.newStatus, adjustmentDate: this.state.adjustmentDate, adjustmentTime: this.state.adjustmentTime };
                break;
            case 3:
                rentalUpdated = { ...this.props.selectedRental, status: this.state.newStatus, deliveryDate: this.state.deliveryDate, deliveryTime: this.state.deliveryTime };
                break;
            default:
                rentalUpdated = { ...this.props.selectedRental, status: this.state.newStatus };
                break;
        }

        this.props.putRental(rentalUpdated).then(r => {
            if (get(r, 'response.success')) {
                const id = get(r, 'response.data.id')
                id && this.props.onClose();
            }
        });
    };

    handleDateChange = (field, date) => {
        this.setState(
            { [field]: date },
            () => {
                if (field === 'adjustmentDate')
                    this.props.getAvailableAdjustmentTimes(date.format()).then(() => this.setState({ adjustmentTime: '' }));

                if (field === 'deliveryDate')
                    this.props.getAvailableDeliveryTimes(date.format()).then(() => this.setState({ deliveryTime: '' }));
            }
        )
    };

    render() {
        const status = get(this.props, 'selectedRental.status');

        // const event = get(this.props, 'selectedRental.event', { date: new Date() });
        // const maxDate = event.date < new Date() ? event.date : new Date(2200, 0);

        const { adjustmentDate, deliveryDate, adjustmentTime, deliveryTime } = this.state;
        const { availableAdjustmentTimes, availableDeliveryTimes } = this.props;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen={this.props.fullScreen}
            >
                <DialogTitle id="form-dialog-title">Alterar Status</DialogTitle>
                <DialogTitle id="form-dialog-subtitle">Status Atual - {RentalStatus[status]}</DialogTitle>
                <DialogTitle id="form-dialog-subtitle">Novo Status:</DialogTitle>
                <DialogContent>
                    <Select
                        value={this.state.newStatus}
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'newStatus',
                            id: 'newStatus',
                        }}
                    >
                        <MenuItem value={0}>Reservado</MenuItem>
                        <MenuItem value={1}>Ajuste marcado</MenuItem>
                        <MenuItem value={3}>Entrega marcada</MenuItem>
                        <MenuItem value={4}>Entregue para cliente</MenuItem>
                        <MenuItem value={5}>Devolvido</MenuItem>
                        <MenuItem value={6}>Cancelado</MenuItem>
                    </Select>

                    {this.state.newStatus === 1 &&
                        <Grid item>
                            <InlineDatePicker
                                keyboard
                                clearable
                                variant="outlined"
                                margin="normal"
                                label="Data Ajuste"
                                onChange={(date) => this.handleDateChange('adjustmentDate', date)}
                                value={adjustmentDate}
                                format="DD/MM/YYYY"
                                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            />

                            <TextField
                                id="adjustmentTime"
                                name="adjustmentTime"
                                select
                                label="Hora Ajuste"
                                value={adjustmentTime}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                style={{ minWidth: '200px' }}
                                disabled={!Array.isArray(availableAdjustmentTimes) || !availableAdjustmentTimes.length}
                                helperText={!adjustmentDate && "Selecione a data do ajuste para ver os horários disponíveis"}
                            >
                                {availableAdjustmentTimes.map(option => (
                                    <MenuItem key={option.time}
                                        value={option.time}
                                        disabled={!option.available}
                                        style={{ backgroundColor: !option.available && '#ffb2b2' }}>
                                        {`${option.time} - ${option.count}/2`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }

                    {this.state.newStatus === 3 &&
                        <Grid item>
                            <InlineDatePicker
                                keyboard
                                clearable
                                variant="outlined"
                                margin="normal"
                                label="Data Entrega"
                                onChange={(date) => this.handleDateChange('deliveryDate', date)}
                                value={deliveryDate}
                                format="DD/MM/YYYY"
                                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            />

                            <TextField
                                id="deliveryTime"
                                name="deliveryTime"
                                select
                                label="Hora Entrega"
                                value={deliveryTime}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                style={{ minWidth: '200px' }}
                                disabled={!Array.isArray(availableDeliveryTimes) || !availableDeliveryTimes.length}
                                helperText={!deliveryDate && "Selecione a data da entrega para ver os horários disponíveis"}
                            >
                                {availableDeliveryTimes.map(option => (
                                    <MenuItem key={option.time}
                                        value={option.time}
                                        disabled={!option.available}
                                        style={{ backgroundColor: !option.available && '#ffb2b2' }}>
                                        {option.time}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="transparent">
                        Voltar
            </Button>
                    <Button onClick={this.handleSubmit} color="primary">
                        Salvar
            </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    availableAdjustmentTimes: state.resources.availableAdjustmentTimes,
    availableDeliveryTimes: state.resources.availableDeliveryTimes,
});

function mapDispatchToProps(dispatch) {
    return {
        putRental: (rental) => dispatch(putRental(rental.id, rental)),
        getAvailableAdjustmentTimes: (date) => dispatch(fetchAvailableAdjustmentTimes(date)),
        getAvailableDeliveryTimes: (date) => dispatch(fetchAvailableDeliveryTimes(date)),
    }
}

StatusChangeDialog.defaultProps = {
    availableAdjustmentTimes: [],
    availableDeliveryTimes: []
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(StatusChangeDialog)));
