import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './auth';
import resourcesReducer from './resources';
import contractReducer from './rentals';
import uiReducer from './ui';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    resources: resourcesReducer,
    contract: contractReducer,
    ui: uiReducer
})

export default createRootReducer;