import React from "react";
import { Grid, Drawer, IconButton } from "@material-ui/core";
import { RegularCard, WithAll } from "components";
import { fetchAllEvents } from 'ducks/events'
import EventsTable from "./EventsTable";
import CloseIcon from '@material-ui/icons/Close';
import Details from "./Details";
import { withRouter, Route } from "react-router-dom";

const List = (props) => {
    const handleItemSelected = (event, item) => {
        event.preventDefault()
        const { history } = props;
        history.push(`/events/${item.id}`)
    }

    return (
        <React.Fragment>

            <RegularCard cardTitle="Eventos">

                    <EventsTable 
                    onRefresh={props.refresh} data={props.data} 
                    orderBy='date' 
                    order='asc'
                    handleItemSelected={handleItemSelected} 
                    embeddedButton={true}
                    />
            </RegularCard>
            <Route path="/events/:id">
                {({ match, history }) => {
                    const isMatch =
                        match &&
                            match.params ? true : false;
                    return (
                        <Drawer
                            variant="persistent"
                            open={isMatch}
                            anchor="right"
                        >
                            {isMatch ? (
                                <Grid container style={{ maxWidth: '500px' }}>
                                    <IconButton onClick={() => history.replace("/events")} style={{marginTop: '24px', marginBottom: '24px'}}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Details />
                                </Grid>
                            ) : null}
                        </Drawer>
                    );
                }}
            </Route>
        </React.Fragment>

    );
}


export default withRouter(WithAll('events', fetchAllEvents)(List));

