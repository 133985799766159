import React, { Fragment } from "react";
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Grid,
  Paper,
  Button
} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { Primary } from "components";

import PropTypes from "prop-types";

import tableStyle from "assets/jss/styles/tableStyle";
import TablePaginationActions from "./TablePaginationActions";
import CustomTableHead from "./TableHead";
import XLSX from 'xlsx';

function generateSheet(data, headerFormatter, exportName) {
  var ws = XLSX.utils.json_to_sheet(data.map(headerFormatter));

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, exportName);

  XLSX.writeFile(wb, `${exportName}.xlsx`)
}

class CustomTable extends React.PureComponent {
  state = {
    page: 0,
    rowsPerPage: this.props.rowsPerPage,
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    const value = event.target.value;
    const rowsPerPage = Number.isInteger(value) ? value : this.props.data.length

    this.setState({ rowsPerPage: rowsPerPage });
  };

  render() {
    const { classes, cells, tableHeaderColor, handleItemSelected, fixed, rowsPerPageOptions,
      onRefresh, emptyMessage, enabledSelector, handleRequestSort, data,
      orderBy, order, colorPicker, 
      exportable,exportFormatter,exportName } = this.props;

    const { page, rowsPerPage } = this.state;
    const headerProps = { tableHeaderColor, orderBy, order };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    if (!data.length)
      return (
        <Paper>

          <Grid style={{ marginTop: 30 }} container direction='column' alignItems='center' justify='center'>
            <Primary>Nenhum item encontrado.</Primary>
            {
              onRefresh &&
              <Fragment>
                <Primary>{emptyMessage || "Certifique-se de que existem items cadastrados e tente novamente."}</Primary>
                <IconButton title="Tentar novamente" onClick={onRefresh} className={classes.button} aria-label="Refresh" color="primary">
                  <RefreshIcon />
                </IconButton>
              </Fragment>
            }
          </Grid>
        </Paper>
      )

    return (
      // <ItemGrid xs={12} sm={12} md={12}>
      <Paper className={classes.tableResponsive}>
        <React.Fragment>

          {exportable &&
            (<Button variant="outlined" onClick={() => generateSheet(data, exportFormatter, exportName)}>
              Exportar
    </Button>)}
          <Table className={classes.table}>
            {cells && (<CustomTableHead classes={classes} sortHandler={handleRequestSort} {...headerProps} cells={cells} />)}
            <TableBody>

              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => enabledSelector(item) ?

                (
                  <TableRow hover key={item.id} onClick={event => handleItemSelected(event, item)} style={{ backgroundColor: colorPicker(item) }}>
                    {cells.map((c, index) => (
                      <TableCell key={c.id} className={classes.tableCell} numeric={c.numeric}>
                        {c.format ? c.format(item[c.id]) : item[c.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                )
                :
                <TableRow hover key={item.id} classes={{ hover: classes.hover }} style={{ backgroundColor: colorPicker(item) }}>
                  {cells.map((c, index) => (
                    <TableCell key={c.id} className={classes.tableCell} numeric={c.numeric}>
                      {c.format ? c.format(item[c.id]) : item[c.id]}
                    </TableCell>
                  ))}
                </TableRow>
              )
              }

              {fixed && emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={data.length}
                  page={page}
                  onChangePage={this.handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelRowsPerPage='Items por página'
                  ActionsComponent={TablePaginationActions}
                />
                {
                  onRefresh &&
                  <TableCell>
                    <IconButton onClick={onRefresh} className={classes.button} aria-label="Refresh" color="primary">
                      <RefreshIcon />
                    </IconButton>
                  </TableCell>
                }

              </TableRow>

            </TableFooter>
          </Table>
        </React.Fragment>

      </Paper>
      // </ItemGrid>
    );
  }
}

CustomTable.defaultProps = {
  data: [],
  exportName: 'Relatório',
  exportFormatter: (item, index) => item,
  exportable: false,
  orderBy: '',
  order: 'desc',
  tableHeaderColor: "primary",
  fixed: true,
  rowsPerPageOptions: [50, 100, 'Todos'],
  rowsPerPage: 50,
  enabledSelector: (x) => true,
  colorPicker: (x) => ''
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default withStyles(tableStyle)(CustomTable);