import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import List from './List';
import Form from './Form';

const Products = () =>
    (
        <Switch>
            <Route path='/products/create' component={Form} />
            <Route path='/products/:id/edit' exact component={Form} />
            <Route path='/products/available' exact component={List} />
            <Route path='/products' component={List} />
            <Redirect to='/' />
        </Switch>
    );

export default Products;