import React from "react";
import memoize from "memoize-one";

const AsTable = (WrappedComponent, filter) => {

    return class extends React.Component {
        state = {
            filter: { ...filter, ...this.props.filter },
            order: this.props.order,
            orderBy: this.props.orderBy,
        }

        sort = memoize(
            (data, order, orderBy) => order === 'desc'
                ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
        );

        handleFilterChange = event => {
            const id = event.target.id || event.target.name;
            let value = '';

            if (typeof event.target.value == typeof true)
                value = event.target.value;
            else
                value = event.target.value || '';

            this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    [id]: value
                },
            }));
        };

        handleRequestSort = (property) => {
            const orderBy = property;
            let order = 'desc';

            if (this.state.orderBy === property && this.state.order === 'desc') {
                order = 'asc';
            }

            this.setState({ order, orderBy });
        };

        render() {
            const { data } = this.props;
            const { order, orderBy } = this.state;
            const sortedData = this.sort(data, order, orderBy)

            return <WrappedComponent
                data={sortedData}
                handleFilterChange={this.handleFilterChange}
                handleRequestSort={this.handleRequestSort}
                {...this.props}
                {...this.state}
            />;
        }
    };
}

export default AsTable;
