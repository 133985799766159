import React, { Component } from 'react';
import {
    RegularCard,
    Button
} from "components";

import validator from "utils/validator";
import moment from 'moment';
import { connect } from 'react-redux';
import { postClient, putClient, fetchClient } from 'ducks/clients';
import { withRouter, Link } from 'react-router-dom';
import FormInputs from './FormInputs';
import constraints from './constraints';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';

class Form extends Component {
    state = {
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        telNumber: '',
        rg: '',
        birthDate: null,

        addressCode: '',
        addressLine: '',
        addressNumber: '',
        city: '',
        district: '',
        state: '',
        facebookUri: '',
        instagramUri: '',
        twitterUri: '',

        validations: {}
    }

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        id && this.props.getClient(id).then(x => {
            const client = x.response;
            this.setState({
                name: client.name,
                email: client.email,
                cpf: client.cpf,
                cellNumber: client.cellNumber,
                telNumber: client.telNumber,
                rg: client.rg,
                birthDate: client.birthDate ? moment(client.birthDate) : null,

                addressCode: client.addressCode,
                addressLine: client.addressLine,
                addressNumber: client.addressNumber,
                city: client.city,
                district: client.district,
                state: client.state,


                facebookUri: client.facebookUri,
                instagramUri: client.instagramUri,
                twitterUri: client.twitterUri,
            })
        });
    }

    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleNumericChange = (event, value) => {
        const { id } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleCEPFocusChange = event => {
        const { id, value } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }),
            () => {
                if (this.state.validations[id]) {
                    return;
                } else {
                    fetch(`https://viacep.com.br/ws/${value}/json/`)
                        .then(response => response.json())
                        .then(json => this.setState(({
                            addressLine: !json.erro ? `${json.logradouro} - ${json.bairro} - ${json.localidade} - ${json.uf}` : ''
                        })))
                }
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (prevState) => ({ validations: validator(prevState, constraints) }),
            () => {
                const { id } = this.props.match.params;
                const client = { ...this.state };

                if (id) {
                    this.props.putClient(id, client).then((data) => data.response && this.props.history.push(`/clients/${id}`))
                } else {
                    this.props.postClient(client).then((data) => data.response && this.props.history.push(`/clients/${data.response.data.id || ''}`))
                }

                // if (this.state.validations !== true) {
                //     return;
                // } else {
                //     const { id } = this.props.match.params;
                //     const client = { ...this.state, acquisitionDate: new Date(this.state.acquisitionDate) };

                //     if (id) {
                //         this.props.putClient(id, client).then((data) => data.response && this.props.history.push(`/clients/${id}`))
                //     } else {
                //         this.props.postClient(client).then((data) => data.response && this.props.history.push(`/clients/${data.response.data.id || ''}`))
                //     }
                // }
            });
    };

    render() {
        const { id } = this.props.match.params;
        const title = id ? `Editar Cliente` : "Novo Cliente";
        const subtitle = id ? `Você está editando os dados do cliente ${this.props.selectedClient.name}.` : "Insira os dados do novo cliente.";
        const {
            validations,
            name,
            email,
            cpf,
            cellNumber,
            telNumber,
            rg,
            birthDate,
            addressCode,
            addressLine,
            facebookUri,
            instagramUri,
            twitterUri
        } = this.state;


        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
            >
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <FormInputs
                            handleChange={this.handleChange}
                            handleFocusChange={this.handleFocusChange}
                            handleCEPFocusChange={this.handleCEPFocusChange}
                            validations={validations}
                            name={name}
                            email={email}
                            cpf={cpf}
                            cellNumber={cellNumber}
                            telNumber={telNumber}
                            rg={rg}
                            birthDate={birthDate}
                            addressCode={addressCode}
                            addressLine={addressLine}
                            facebookUri={facebookUri}
                            instagramUri={instagramUri}
                            twitterUri={twitterUri}
                        />

                        <Button color="primary" type='submit' style={{ marginTop: 48 }}>Salvar Cliente</Button>
                        <Link to="/clients" style={{ alignSelf: "flex-start" }}>Voltar Para Lista</Link>
                    </Grid>
                </form>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedClient: state.resources.selectedResource,
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        putClient: (clientId, client) => dispatch(putClient(clientId, client)),
        postClient: client => dispatch(postClient(client)),
        getClient: (id) => dispatch(fetchClient(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));

