import React from "react";
import { Switch, Route } from "react-router-dom";

import List from './List';
import Form from './Form';
import Edit from "./Edit";

const Rentals = () =>
    (
        <Switch>
            <Route path='/rentals/create' component={Form} />
            <Route path='/rentals/:id/edit' exact component={Edit} />
            <Route path='/rentals' component={List} />
        </Switch>
    );

export default Rentals;