import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import {
    RegularCard,
    Button
} from "components";
import validator from "utils/validator";

import { connect } from 'react-redux';
import { postEvent, putEvent, fetchEvent } from 'ducks/events';
import { withRouter, Link } from 'react-router-dom';
import FormInputs from './FormInputs';
import constraints from './constraints';
import moment from 'moment';
import get from 'lodash/get';

class Form extends Component {
    state = {
        name: '',
        local: '',
        date: null,
        description: '',
        validations: {}
    }

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        id && this.props.getEvent(id).then(x => {
            const event = x.response;
            this.setState({ name: event.name, local: event.local, description: event.description, date: event.date ? moment(event.date) : null })
        });
    }

    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (prevState) => ({ validations: validator(prevState, constraints) }),
            () => {
                if (this.state.validations !== true) {
                    return;
                } else {
                    const { id } = this.props.match.params;
                    const event = { ...this.state };

                    if (id) {
                        this.props.putEvent(id, event).then((data) => data.response && this.props.history.push(`/events/${id}`))
                    } else {
                        this.props.postEvent(event).then((data) => data.response && this.props.history.push(`/events/${data.response.data.id || ''}`))
                    }
                }
            });
    };

    render() {
        const { id } = this.props.match.params;
        const title = id ? `Editar Evento` : "Novo Evento"
        const { validations, date, local, name, description } = this.state;
        const subtitle = id ? `Você está editando os dados do evento ${this.props.selectedEvent.name}.` : "Insira os dados do novo evento."

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
            >
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <FormInputs handleChange={this.handleChange}
                            handleDateChange={this.handleDateChange}
                            handleFocusChange={this.handleFocusChange}
                            name={name}
                            date={date}
                            local={local}
                            description={description}
                            validations={validations} />

                        <Button color="primary" type='submit' style={{ marginTop: 48 }}>Salvar Evento</Button>
                        <Link to="/events" style={{ alignSelf: "flex-start" }}>Voltar Para Lista</Link>
                    </Grid>
                </form>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedEvent: state.resources.selectedResource
});

function mapDispatchToProps(dispatch) {
    return {
        putEvent: (eventId, event) => dispatch(putEvent(eventId, event)),
        postEvent: event => dispatch(postEvent(event)),
        getEvent: (id) => dispatch(fetchEvent(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));