import React from "react";
import { Grid, IconButton, Drawer } from "@material-ui/core";
import { RegularCard, WithAll } from "components";
import { fetchAllRentals } from 'ducks/rentals'
import { withRouter } from "react-router-dom";
import RentalsTable from "./RentalsTable";
import { Route } from "react-router-dom";
import Details from './Details';
import CloseIcon from '@material-ui/icons/Close';

const List = (props) => {
    const handleItemSelected = (event, item) => {
        event.preventDefault()
        const { history } = props;
        history.push(`/rentals/${item.id}`)
    }

    return (
        <React.Fragment>
            <RegularCard cardTitle="Locações">
                <Grid container>
                    <RentalsTable onRefresh={props.refresh}
                        data={props.data}
                        handleItemSelected={handleItemSelected}
                        embeddedButton={true} />

                </Grid>
            </RegularCard>
            <Route path="/rentals/:id">
                {({ match, history }) => {
                    const isMatch = match && match.params ? true : false;
                    return isMatch && (
                    <Drawer 
                    variant="persistent"
                            open={isMatch}
                            anchor="right"
                            >
                                <Grid container style={{ maxWidth: '500px' }}>
                                    <IconButton onClick={() => history.replace("/rentals")} style={{ marginTop: '24px', marginBottom: '24px' }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Details />
                                </Grid>
                        </Drawer>
                    );
                }}
            </Route>
        </React.Fragment>

    );
}

export default withRouter(WithAll('rentals', fetchAllRentals)(List));
