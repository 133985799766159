import React, { Component } from 'react';
import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import { Switch, Route } from 'react-router'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { ItemGrid, RegularCard, Button } from "components";
import get from 'lodash/get';
import ProductSelector from '../Products/ProductSelector.jsx';
import ClientSelector from './ClientSelector';
import EventSelector from './EventSelector';
import ContractForm from './ContractForm';
import { updateContract, postRental } from 'ducks/rentals';
import {SHOW_MESSAGE} from 'ducks/ui';

class Form extends Component {
    state = {
        activeStep: 0,
        steps: [
            { label: "Cliente" },
            { label: "Evento" },
            { label: "Produto" },
            { label: "Contrato" },
        ]
    }

    componentDidMount() {
        this.props.history.replace('/rentals/create', {})

        this.setState({
            activeStep: 0
        }, () => this.props.cleanContract())
    }


    componentDidUpdate(prevProps) {
        const { activeStep } = this.state;
        const prevItem = get(get(prevProps, 'location.state'), activeStep);
        const newItem = get(get(this.props, 'location.state'), activeStep);

        if (newItem) {
            if (get(prevItem, 'id') !== get(newItem, 'id')) {
                this.setState(prevState => {
                    const newArr = prevState.steps;
                    newArr[activeStep].selected = newItem;
                    return {
                        steps: newArr
                    };
                });
            }
        }
    }


    handleNext = () => {
        const { activeStep, steps } = this.state;
        if (activeStep === steps.length - 1) {
            this.props.postRental(this.props.contract).then(r => {
                if (get(r, 'response.success')) {
                    const id = get(r, 'response.data.id')
                    if (id) {
                        id && this.props.history.push(`/rentals/${id}`)
                        this.props.cleanContract();
                    }
                }
            });
            return;
        }

        const selected = steps[activeStep].selected;

        if (selected)
            this.setState({
                activeStep: activeStep + 1
            }, () => {
                switch (activeStep) {
                    case 0:
                        this.props.history.push('/rentals/create/event', this.state);
                        break;
                    case 1:
                        this.props.history.push(`/rentals/create/product`, this.state);
                        break;
                    case 2:
                        this.props.history.push('/rentals/create/contract', this.state);
                        break;
                    default:
                        break;
                }
            });
        else
            this.props.showMessage('Selecione um item para avançar');
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        }, () => {
            this.props.history.goBack();
        });
    };

    handleItemSelected = (event, item) => {
        this.setState(prevState => {
            const newArr = prevState.steps;
            if (get(newArr[prevState.activeStep], 'selected.id') === item.id)
                this.handleNext();
            newArr[prevState.activeStep].selected = item;
            return {
                steps: newArr
            };
        });
    };

    render() {
        const { activeStep, steps } = this.state;
        return (
            <RegularCard
                cardTitle="Nova Locação"
                cardSubtitle="Siga os passos para cadastrar uma nova locação.">
                <ItemGrid xs={12} sm={12} md={12}>
                    <Grid container direction='column'>

                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((step) => {
                                return (
                                    <Step key={step.label}>
                                        <StepLabel>
                                            {step.label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        <Switch>
                            <Route exact path="/rentals/create" render={(p) => <ClientSelector {...p} selected={get(this.state, 'steps[0].selected')}
                                onSelected={this.handleItemSelected}
                                steps={steps} activeStep={activeStep} handleBack={this.handleBack} handleNext={this.handleNext}>
                            </ClientSelector>} />

                            <Route exact path="/rentals/create/event" render={(p) => <EventSelector {...p} selected={get(this.state, 'steps[1].selected')}
                                onSelected={this.handleItemSelected}
                                steps={steps} activeStep={activeStep} handleBack={this.handleBack} handleNext={this.handleNext}>
                            </EventSelector>} />

                            <Route exact path="/rentals/create/product" render={(p) =>
                                <ProductSelector {...p} selected={get(this.state, 'steps[2].selected')}
                                    actionParams={{ eventId: get(this.state, 'steps[1].selected.id') }}
                                    onSelected={this.handleItemSelected} showAvailable={true}>
                                    <StepperButtons steps={steps} activeStep={activeStep} handleBack={this.handleBack} handleNext={this.handleNext} />
                                </ProductSelector>} />
                            <Route exact path="/rentals/create/contract" component={ContractForm} />
                        </Switch>

                        {activeStep === steps.length - 1 &&
                            <StepperButtons isFetching={this.props.isFetching} steps={steps} activeStep={activeStep} handleBack={this.handleBack} handleNext={this.handleNext} />
                        }

                    </Grid>

                    <Link to="/rentals">Voltar Para Lista</Link>
                </ItemGrid>
            </RegularCard>
        );
    }

}

const StepperButtons = ({ isFetching, steps, activeStep, handleBack, handleNext }) => (
    <div>
        <Button
            disabled={activeStep === 0}
            onClick={handleBack}
        >
            Voltar
    </Button>
        <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isFetching}
        >
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
        </Button>
    </div>
)

const mapStateToProps = (state) => ({
    selectedEvent: state.resources.selectedResource,
    contract: state.contract.newContract,
    isFetching: state.resources.isFetching
});

function mapDispatchToProps(dispatch) {
    return {
        showMessage: (message) => dispatch({ type: SHOW_MESSAGE, message }),
        cleanContract: () => dispatch(updateContract(null)),
        postRental: (contract) => dispatch(postRental(contract))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));


