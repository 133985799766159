import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ProductSelector from "./ProductSelector";
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
import { Grid, Typography } from "@material-ui/core";

const styles = {
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
};

class AvailableList extends React.Component {
    state = {
        activeStep: 0,
        date: moment().utc()
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    render() {
        const { onSelected, children } = this.props;
        const { date, activeStep } = this.state
        return (
            <Grid container item direction='column' spacing={24}>
                <Grid item md={8}>

                    {activeStep === 0 &&
                        <DatePicker
                            fullWidth={true}
                            value={date}
                            onChange={(date) => this.setState({ date })}
                            keyboard
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Data"
                            invalidDateMessage="Formato inválido"
                            format="DD/MM/YYYY"
                        />
                    }
                </Grid>

                <Grid item md={12}>

                    {activeStep === 1 &&
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom align="center">
                                {`Disponibilidade de vestidos em ${date.format('LL')}`}
                            </Typography>
                            <ProductSelector
                                showAll
                                actionParams={{ date: date.format() }}
                                onSelected={onSelected}
                                orderBy="name"
                                showAvailable={false}>
                            </ProductSelector>
                        </React.Fragment>
                    }

                </Grid>
                {children}

                <Grid item md={8}>
                    <MobileStepper
                        variant="progress"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={this.handleNext} disabled={activeStep === 1}>
                                Filtrar
                            <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Voltar
                            </Button>
                        }
                    />
                </Grid>
            </Grid>
        );
    }
}

AvailableList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


export default (withStyles(styles, { withTheme: true })(AvailableList));
