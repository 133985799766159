export const DISMISS_MESSAGE = 'dmu-manager-web/resources/DISMISS_MESSAGE'
export const SHOW_MESSAGE = 'dmu-manager-web/resources/SHOW_MESSAGE'
export const TOGGLE_DRAWER = 'dmu-manager-web/resources/TOGGLE_DRAWER'

const initialState = {
    message: null,
    messageShown: false,
    drawerVisible: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                messageShown: false,
                message: payload.message
            }

        case DISMISS_MESSAGE:
            return {
                ...state,
                messageShown: true,
                message: null
            }
            case TOGGLE_DRAWER:
            return {
                ...state,
                drawerVisible: !state.drawerVisible,
            }
        default:
            return state;
    }
}

export function showMessage() {
    return {
        type: SHOW_MESSAGE
    }
}

export function dismissMessage() {
    return {
        type: DISMISS_MESSAGE
    }
}

export function toggleDrawer() {
    return {
        type: TOGGLE_DRAWER
    }
}