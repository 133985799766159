import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import {
    Grid,
    Typography,
    Paper,
    Tabs,
    Tab
} from "@material-ui/core";

import {
    RegularCard,
    Button,
} from "components";

import get from 'lodash/get';

import { fetchRental, deleteRental } from 'ducks/rentals';
import FormInput from '../../../components/CustomInput/FormInput';
import FormNumericInput from '../../../components/CustomInput/FormNumericInput';
import moment from 'moment';
import { RentalStatus } from '../../../utils';
import StatusChangeDialog from './StatusChangeDialog';
import RentalPayments from './RentalPayments';
import { roles } from "utils/variables";

class Details extends Component {
    state = {
        tabPos: 0,
        statusDialogOpen: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        id && this.props.getRental(id).then(r => {
            get(r, 'type') === "GET_SINGLE_FAILURE" && this.props.history.goBack()
        });
    }


    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.match.params;
        const prevId = prevProps.match.params.id;
        if (prevId)
            if (id !== prevId)
                id && this.props.getRental(id).then(r => {
                    get(r, 'type') === "GET_SINGLE_FAILURE" && this.props.history.goBack()
                });
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteRental(id).then((data) => data.response.success && this.props.history.push(`/rentals`))

    }

    handleDialogOpen = () => {
        this.setState({ statusDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ statusDialogOpen: false });
    };

    render() {

        const { id } = this.props.match.params;
        const { tabPos, statusDialogOpen } = this.state;
        const { client, event, product, adjustmentDate, deliveryDate, discount, seller, contractUri, additionalValue, status } = this.props.selectedRental;
        const title = "Dados de Locação";
        const subtitle = `Você está visualizando os dados da locação selecionada.`
        const adjustmentDateFormatted = moment(adjustmentDate).isValid() ? moment(adjustmentDate).format('LLL') : moment().format('LLL');
        const deliveryDateFormatted = moment(deliveryDate).isValid() ? moment(deliveryDate).format('LLL') : moment().format('LLL');

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
            >
                <span onClick={this.props.handleClose}>Voltar Para Lista</span>

                <Grid container direction='column' alignItems="stretch">

                    <Grid item style={{ marginTop: 24, marginBottom: 24 }}>
                        <Paper>
                            <Tabs
                                value={tabPos}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.handleTabChange}>

                                <Tab label="Informações" />
                                <Tab label="Pagamentos" />
                            </Tabs>
                        </Paper>
                    </Grid>
                    {(this.props.selectedRental && tabPos === 0) &&
                        <Grid container direction="column" alignItems="center" spacing={24}>
                            <h3>{`STATUS - ${RentalStatus[status] || ""}`}</h3>
                            <Typography > <a href={contractUri} rel="noopener noreferrer" target="_blank">Ver Contrato</a> </Typography>

                            <Grid item container direction='row' justify="space-around" alignItems="center" md={6} spacing={24}>
                                <Button onClick={this.handleDialogOpen} color='warning'>
                                    Alterar Status
                            </Button>

                                <Link to={`/rentals/${id}/edit`}>
                                    <Button color='primary'>
                                        Editar dados
                                    </Button>
                                </Link>
                                {this.props.userRole === roles.Admin &&
                                    <Button onClick={this.handleDeleteClick} color='danger'>
                                        Excluir
                                    </Button>
                                }
                            </Grid>


                            <Grid container item direction='column' md={12} spacing={24}>

                                <FormInput
                                    label="Cliente"
                                    id="client"
                                    value={`${get(client, 'name')} - ${get(client, 'cpf')}`}
                                    disabled
                                />

                                <FormInput
                                    label="Evento"
                                    id="event"
                                    value={`${get(event, 'name')} - ${moment(get(event, 'date')).format('LL')}`}
                                    disabled
                                />

                                <FormInput
                                    label="Produto"
                                    id="product"
                                    value={`${get(product, 'code')} - ${get(product, 'name')}`}
                                    disabled
                                />


                                <FormInput
                                    label="Data Ajuste"
                                    id="adjustmentDate"
                                    value={adjustmentDateFormatted}
                                    disabled
                                />

                                <FormInput
                                    label="Data Entrega"
                                    id="deliveryDate"
                                    value={deliveryDateFormatted}
                                    disabled
                                />

                                <FormNumericInput
                                    label="Valor Total"
                                    id="rentalValue"
                                    value={get(product, 'rentalValue')}
                                    disabled
                                />


                                <FormNumericInput
                                    label="Valor Desconto"
                                    id="discount"
                                    disabled
                                    value={discount}
                                />

                                <FormNumericInput
                                    label="Valor Adicional"
                                    id="additionalValue"
                                    disabled
                                    value={additionalValue}
                                />

                                <FormInput
                                    label="Vendedora"
                                    id="seller"
                                    value={get(seller, 'fullName') || ''}
                                    disabled
                                />

                            </Grid>
                        </Grid>
                    }

                    {(this.props.selectedRental && tabPos === 1) &&
                        <Grid container direction="column" alignItems="center">
                            <RentalPayments key={id} selectedRental={this.props.selectedRental} />
                        </Grid>
                    }

                    <StatusChangeDialog key={id}
                        open={statusDialogOpen}
                        onClose={this.handleDialogClose}
                        selectedRental={this.props.selectedRental} />

                </Grid>
            </RegularCard >
        );
    }
}

const mapStateToProps = (state) => ({
    selectedRental: state.resources.selectedResource,
    userRole: state.auth.userRole,
});

function mapDispatchToProps(dispatch) {
    return {
        deleteRental: (id) => dispatch(deleteRental(id)),
        getRental: (id) => dispatch(fetchRental(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
