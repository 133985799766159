const constraints = {
    name: {
        presence: { message: "Por favor, informe um nome para o evento.", allowEmpty: false },
        length: {
            minimum: 3,
            tooShort: "O nome precisa ter pelo menos 3 caracteres"
        }
    },

    local: {
        presence: { message: "Por favor, informe o local do evento.", allowEmpty: false },
        length: {
            minimum: 3,
            tooShort: "O local precisa ter pelo menos 3 caracteres"
        }
    },
};

export default constraints;