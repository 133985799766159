import React from "react";
import { WithAll, Button } from "components";
import EventsTable from '../Events/EventsTable';
import get from 'lodash/get';
import FormInputs from "../Events/FormInputs";
import { Tabs, Tab } from "@material-ui/core";
import validator from "utils/validator";
import { postEvent, fetchAllEvents } from 'ducks/events';
import { connect } from 'react-redux';
import constraints from "../Events/constraints";

class EventSelector extends React.Component {
    state = {
        tabPos: 0,
        name: '',
        local: '',
        date: null,
        description: '',
        validations: {}
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.tabPos === 0)
            this.props.handleNext();
        else {
            this.setState(
                (prevState) => ({ validations: validator(prevState, constraints) }),
                () => {
                    if (this.state.validations !== true) {
                        return;
                    } else {
                        const event = { ...this.state };

                        this.props.postEvent(event).then((data) => {
                            if (get(data, 'response.success')) {
                                this.props.onSelected(null, get(data, 'response.data'))
                                this.props.handleNext();
                            }
                        });
                    }
                });
        }
    };

    render() {
        const { refresh, onSelected, selected, steps, activeStep, handleBack } = this.props;
        const { tabPos, name, local, date, description, validations } = this.state;
        let { data } = this.props
        data = data.filter(x => new Date(x.date) > Date.now())

        return (
            <div>
                <Tabs value={tabPos} onChange={this.handleTabChange}>
                    <Tab label="Lista" />
                    <Tab label="Novo" />
                </Tabs>

                {tabPos === 0 &&
                    <div style={{ textAlign: 'center' }}>
                        {selected && <h3>{`${get(selected, 'name')} - ${(new Date(get(selected, 'date'))).toLocaleDateString('pt-BR')}`}</h3>}
                        <EventsTable onRefresh={refresh} fixed={data.length > 10} data={data} handleItemSelected={onSelected} />
                    </div>
                }

                {tabPos === 1 &&
                    <FormInputs handleChange={this.handleChange}
                        handleFocusChange={this.handleFocusChange}
                        name={name}
                        date={date}
                        local={local}
                        description={description}
                        validations={validations} />
                }

                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                    </Button>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postEvent: event => dispatch(postEvent(event)),
    }
}



export default WithAll('events', fetchAllEvents)(connect(null, mapDispatchToProps)(EventSelector));
