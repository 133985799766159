import React from "react";
import { withStyles, FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import PropTypes from "prop-types";
import cx from "classnames";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import customInputStyle from "assets/jss/styles/customInputStyle";

const CustomInput = ({ ...props }) => {
  const {
    classes,
    formControlProps,
    labelText,
    name,
    labelProps,
    inputProps,
    success,
    errors,
    type,
    multiline,
    optional,
    endAdornment
  } = props;

  const labelClasses = cx({
    [" " + classes.labelRootSuccess]: success && !errors
  });

  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  });

  let customInputComponent = 'input';
  if (type === 'number') {
    customInputComponent = NumberFormatCustom;
  } else {
    customInputComponent = inputProps.mask ? TextMaskCustom : 'input'
  }

  return (
    <FormControl
      fullWidth={true}
      {...formControlProps}
      className={formControlProps ? formControlProps.className + " " + classes.formControl : null}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={name}
          {...labelProps}
        >
        <span>
          {labelText}{optional && <i> - Opcional</i>}
        </span>
        </InputLabel>
      ) : null}


      <Input
        id={name}
        multiline={multiline}
        endAdornment={endAdornment}
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: classes.underline,
        }}
        inputComponent={customInputComponent}
        inputProps={{ ...inputProps }}
        {...inputProps}
      />

      {errors ?
        <FormHelperText error>
          {errors[0]}
        </FormHelperText>
        : null
      }

      {errors ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomInput);


const TextMaskCustom = (props) => {
  const { inputRef, ...inputProps } = props;
  return (
    <MaskedInput
      {...inputProps}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={inputProps.mask}
      placeholderChar={'\u2000'}
      guide={false}
      keepCharPositions={false}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, ...inputProps } = props;
  const onChange = inputProps.onChange;
  delete inputProps.onChange;
  
  return (
    <NumberFormat
      onValueChange={(values, e) => {
        onChange(e, values.floatValue);
      }}
      {...inputProps}
      getInputRef={inputRef}
      thousandSeparator='.'
      decimalSeparator=','
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      prefix="R$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};