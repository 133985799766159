import { CALL_API } from '../utils/apiMiddleware';
import * as ActionTypes from './resources';
import {push} from 'connected-react-router';

export const fetchAllProducts = () => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/products/`
    }
})

export const fetchAllProductsAvailable = (params) => {
    console.log(params)
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
    return {
        [CALL_API]: {
            types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
            endpoint: `/products/?${query}`
        }
    }
}

export const fetchProduct = (id) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_SINGLE_REQUEST, ActionTypes.GET_SINGLE_SUCCESS, ActionTypes.GET_SINGLE_FAILURE],
            endpoint: `/products/${id}/`
        }
    })
}

export const postProduct = (product, files, history) => {
    return function (dispatch) {
        if (Array.isArray(files) && files.length) {
            var formData = new FormData();
            files.forEach(file => {
                formData.append("files", file);
            });

            fetch(`${process.env.REACT_APP_API_ENDPOINT}/images/upload`, {
                method: 'POST',
                body: formData
            }).then(res => res.json()
                .then(json => {
                    product.images = json.data;
                    dispatch(postProductRequest(product))
                        .then((data) => data.response && dispatch(push(`/products/${data.response.data.id || ''}`)));
                }));
        } else {
            dispatch(postProductRequest(product))
                .then((data) => data.response && dispatch(push(`/products/${data.response.data.id || ''}`)));
        }
    }
}

export const putProduct = (id, product, files, imgs, history) => {
    return function (dispatch) {
        if (Array.isArray(files) && files.length) {
            var formData = new FormData();
            files.forEach(file => {
                formData.append("files", file);
            });

            fetch(`${process.env.REACT_APP_API_ENDPOINT}/images/upload`, {
                method: 'POST',
                body: formData
            }).then(res => res.json()
                .then(json => {
                    product.images = [...imgs.map(x => ({ src: x.src, thumbnail: x.thumbnail })), ...json.data.map(x => ({ src: x, thumbnail: x }))];
                    dispatch(putProductRequest(id, product))
                        .then((data) => data.response && dispatch(push(`/products/${id}`)))
                }));

        } else {
            product.images = imgs.map(x => ({ src: x.src, thumbnail: x.thumbnail }));
            dispatch(putProductRequest(id, product))
                .then((data) => data.response && dispatch(push(`/products/${id}`)))
        }
    }
}

export const postProductRequest = (product) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/products/`,
            method: 'POST',
            body: product
        }
    })
}

export const putProductRequest = (productId, product) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.PUT_REQUEST, ActionTypes.PUT_SUCCESS, ActionTypes.PUT_FAILURE],
            endpoint: `/products/${productId}`,
            method: 'PUT',
            body: product
        }
    })
}

export const deleteProduct = productId => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.DELETE_REQUEST, ActionTypes.DELETE_SUCCESS, ActionTypes.DELETE_FAILURE],
            endpoint: `/products/${productId}`,
            method: 'DELETE'
        }
    })
}