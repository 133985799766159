import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './ducks';
import api from "./utils/apiMiddleware";
import tokenVerifierMiddleware from "./utils/tokenVerifierMiddleware";

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    createRootReducer(history),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            tokenVerifierMiddleware,
            api
        ),
    ),
)

export { store, history }