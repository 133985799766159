import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'

import {
    Grid,
    Paper,
    Tabs,
    Tab
} from "@material-ui/core";

import {
    ItemGrid,
    RegularCard,
    Button,
    CustomInput,
} from "components";
import { dateToInput } from "utils";

import { deleteEvent, fetchEvent } from 'ducks/events'

import RentalsReferenceTable from "views/Main/Rentals/RentalsReferenceTable.jsx";

const ItemGridMarginStyle = {
    marginTop: 24,
    marginBottom: 24
}

class Details extends Component {
    state = {
        tabPos: 0,
        name: '',
        local: '',
        date: dateToInput(new Date()),
        description: '',
        rentals: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        id && this.props.getEvent(id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.match.params;
        const prevId = prevProps.match.params.id;

        if (id !== prevId)
            id && this.props.getEvent(id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedEvent)
            return {
                name: nextProps.selectedEvent.name || '',
                local: nextProps.selectedEvent.local || '',
                description: nextProps.selectedEvent.description || '',
                date: dateToInput(nextProps.selectedEvent.date) || '',
            };

        return null;
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteEvent(id).then((data) => data.response.success && this.props.history.push(`/events`))

    }

    handleRentalSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/rentals/${item.id}`)
    }

    render() {
        const { id } = this.props.match.params;
        const { name, local, description, date, tabPos } = this.state;
        const title = "Dados de evento";
        const subtitle = `Você está visualizando os dados do evento ${name}.`

        return (
            <React.Fragment>
                <RegularCard
                    cardTitle={title}
                    cardSubtitle={subtitle}
                    footer={
                        <Grid container>
                            <ItemGrid>
                                <Link to={`/events/${id}/edit`}>
                                    <Button color='primary'>
                                        Editar
                                </Button>
                                </Link>
                            </ItemGrid>
                            <ItemGrid>
                                <Button onClick={this.handleDeleteClick} color='danger'>
                                    Excluir
                            </Button>
                            </ItemGrid>
                        </Grid>
                    }
                >
                    <Grid container direction='column'>
                        <ItemGrid style={ItemGridMarginStyle}>
                            <Paper>
                                <Tabs
                                    value={this.state.tabPos}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabChange}>

                                    <Tab label="Informações" />
                                    <Tab label="Locações" />
                                </Tabs>
                            </Paper>
                        </ItemGrid>

                        {tabPos === 0 ?
                            <Grid container direction='column'>
                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Nome"
                                        inputProps={{
                                            disabled: true,
                                            id: "name",
                                            value: name,
                                        }} />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Data"
                                        inputProps={{
                                            disabled: true,
                                            id: "date",
                                            value: date,
                                            type: "date",
                                        }}
                                    />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Local"
                                        inputProps={{
                                            disabled: true,
                                            id: "local",
                                            value: local,
                                        }}
                                    />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Descrição"
                                        inputProps={{
                                            disabled: true,
                                            id: "description",
                                            value: description,
                                        }}
                                    />
                                </ItemGrid>


                            </Grid>
                            :
                            id && <RentalsReferenceTable actionParams={`?eventId=${id}`} onSelected={this.handleRentalSelected} 
                            emptyMessage='Nenhuma locação foi realizada para esse evento.' />
                        }
                    </Grid>
                </RegularCard>
                <Link to="/events">Voltar Para Lista</Link>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedEvent: state.resources.selectedResource
});

function mapDispatchToProps(dispatch) {
    return {
        deleteEvent: (id) => dispatch(deleteEvent(id)),
        getEvent: (id) => dispatch(fetchEvent(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
