import React from "react";
import { TextField, Switch, FormControlLabel, Grid, Paper } from "@material-ui/core";
import { Table, ItemGrid, FilterPanel } from "components";
import { RentalStatus } from "utils";
import moment from 'moment';
import AsTable from "../../../components/HOC/AsTable";
import PropTypes from 'prop-types'
import { NormalizeString } from "../../../utils";
import { Link } from 'react-router-dom';
import { roles } from "../../../utils/variables";
import { Button } from "../../../components";

const ProductsTable = props => {
    const { handleFilterChange, showStatus, rowsPerPage, filter } = props;
    let { data } = props;
    if (showStatus) {
        data = data.filter(x => [5].includes(x.rentalStatus) === false);
        if (!filter.showAvailable) {
            data = data.filter(x => x.rentalStatus !== undefined);
        }
    }

    if (filter.code) {
        data = data.filter(x => x.code && x.code.toString().toLowerCase().trim().includes(filter.code.toLowerCase().trim()));
    }

    if (filter.name) {
        data = data.filter(x => x.name && NormalizeString(x.name).includes(NormalizeString(filter.name)));
    }

    if (filter.color) {
        data = data.filter(x => x.color && NormalizeString(x.color).includes(NormalizeString(filter.color)));
    }

    const cells = showStatus ? [
        { id: 'code', label: "Código" },
        { id: 'name', label: "Nome" },
        { id: 'rentalStatus', label: "Status", format: (x) => x === undefined ? 'Disponível' : RentalStatus[x] },
        { id: 'eventDate', label: "Data do evento", format: (x) => x && moment(x).format("LL") },
        { id: 'eventLocation', label: "Local do evento" },
        { id: 'color', label: "Cor" },
        { id: 'rentalValue', label: "Valor de Locação", format: (x) => `R$ ${x}`, numeric: true },
    ] : [
            { id: 'code', label: "Código" },
            { id: 'name', label: "Nome" },
            { id: 'color', label: "Cor" },
            { id: 'rentalValue', label: "Valor de Locação", format: (x) => `R$ ${x}`, numeric: true },
            { id: 'originalValue', label: "Valor Original", format: (x) => `R$ ${x}`, numeric: true },
        ];

    return (
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12} style={{
                position: 'sticky',
                top: '0',
                padding: '16px',
                zIndex: 50
            }}>
                <Paper style={{ padding: '16px' }}>
                    {props.userRole === roles.Admin && props.embeddedButton &&
                        <ItemGrid xs={12} sm={12} md={12}>
                            <Link to='/products/create'>
                                <Button color='primary'>
                                    Novo produto
                            </Button>
                            </Link>
                        </ItemGrid>
                    }
                    <FilterPanel title='Filtrar Lista'>
                        <TextField
                            id='code'
                            label='Código'
                            value={filter.code}
                            onChange={handleFilterChange} />

                        <TextField
                            id='name'
                            label='Nome'
                            value={filter.name}
                            onChange={handleFilterChange} />

                        <TextField
                            id='color'
                            label='Cor'
                            value={filter.color}
                            onChange={handleFilterChange} />

                    </FilterPanel>
                    {showStatus &&
                        <FormControlLabel
                            control={
                                <Switch
                                    id="showAvailable"
                                    checked={filter.showAvailable}
                                    onChange={(e, c) => handleFilterChange({ ...e, target: { ...e.target, id: "showAvailable", value: c } })}
                                    color="primary"
                                />
                            }
                            label="Mostrar Disponíveis"
                        />
                    }
                </Paper>

            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={12}>
                <Table
                    {...props}
                    data={data}
                    cells={cells}
                    enabledSelector={x => x.rentalStatus === undefined || x.rentalStatus === 6}
                    rowsPerPage={rowsPerPage}
                />
            </ItemGrid>
        </Grid>
    )
}

ProductsTable.propTypes = {
    filter: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired
}

export default AsTable(ProductsTable, {
    code: '',
    name: '',
    color: '',
    showAvailable: true
});