import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import {
    RegularCard,
    Button
} from "components";
import validator from "utils/validator";
import { FormInput, InputsContainer } from 'components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import get from 'lodash/get';
import { postResource, putResource, fetchSingleResource } from 'ducks/resources';
import { Resources } from '../../../utils/variables';

class Form extends Component {
    state = {
        username: '',
        password: '',
        validations: {}
    }

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        id && this.props.getUser(id).then(x => {
            const user = x.response;
            this.setState({
                username: user.username,
            })
        });
    }


    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (prevState) => ({ validations: validator(prevState, constraints) }),
            () => {
                if (this.state.validations !== true) {
                    return;
                } else {
                    const { id } = this.props.match.params;
                    const user = { ...this.state };

                    if (id) {
                        this.props.putUser(id, user).then((data) => get(data, 'response') && this.props.history.push(`/users/${id}`))
                    } else {
                        this.props.postUser(user).then((data) => get(data, 'response') && this.props.history.push(`/users/${data.response.data.id || ''}`))
                    }
                }
            });
    };

    render() {
        const { id } = this.props.match.params;
        const title = id ? `Editar Usuário` : "Novo Usuário"
        const { username, password, validations } = this.state;
        const subtitle = id ? `Você está editando os dados do usuário ${this.props.selectedUser.username}.` : "Insira os dados do novo usuário."

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
            >
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <InputsContainer>
                            <FormInput
                                autoFocus
                                label="Nome de usuário"
                                errors={validations['username']}
                                id="username"
                                value={username}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <FormInput
                                type="password"
                                label="Senha"
                                errors={validations['password']}
                                id="password"
                                value={password}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />
                        </InputsContainer>

                        <Button color="primary" type='submit' style={{ marginTop: 48 }}>Salvar Usuário</Button>
                        <Link to="/users" style={{ alignSelf: "flex-start" }}>Voltar Para Lista</Link>
                    </Grid>

                </form>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedUser: state.resources.selectedResource
});

function mapDispatchToProps(dispatch) {
    return {
        putUser: (userId, user) => dispatch(putResource(Resources.Users, userId, user)),
        postUser: user => dispatch(postResource(Resources.Users, user)),
        getUser: (id) => dispatch(fetchSingleResource(Resources.Users, id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));

const constraints = {
    username: {
        presence: { message: "Por favor, informe o nome de usuário.", allowEmpty: false },
        length: {
            minimum: 3,
            tooShort: "O nome precisa ter pelo menos 3 caracteres"
        }
    },
};