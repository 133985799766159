import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment';
import get from 'lodash/get'
import {
    Grid
} from "@material-ui/core";

import {
    ItemGrid,
    RegularCard,
    Button,
    CustomInput,
} from "components";
import { Resources } from 'utils/variables'

import { deleteResource, fetchSingleResource } from 'ducks/resources'

const ItemGridMarginStyle = {
    marginTop: 24,
    marginBottom: 24
}

class Details extends Component {
    state = {
        tabPos: 0,
        username: '',
        createdAt: '',
        modifiedAt: ''
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        id && this.props.getUser(id).then(r => {
            get(r, 'type') === "GET_SINGLE_FAILURE" && this.props.history.goBack()
            this.setState({ ...r.response })
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteResource('users', id).then((data) => get(data, 'response.success') && this.props.history.push(`/users`))
    }

    render() {
        const { id } = this.props.match.params;
        const { username } = this.state;
        const createdAt = moment(this.state.createdAt).format('LL');
        const modifiedAt = this.state.modifiedAt && moment(this.state.modifiedAt).format('LL');

        const title = "Dados de usuário";
        const subtitle = `Você está visualizando os dados do usuário ${username}.`

        return (
            <React.Fragment>
                <RegularCard
                    cardTitle={title}
                    cardSubtitle={subtitle}
                    footer={
                        <Grid container>
                            <ItemGrid>
                                <Link to={`/users/${id}/edit`}>
                                    <Button color='primary'>
                                        Editar
                                </Button>
                                </Link>
                            </ItemGrid>
                            <ItemGrid>
                                <Button onClick={this.handleDeleteClick} color='danger'>
                                    Excluir
                            </Button>
                            </ItemGrid>
                        </Grid>
                    }
                >
                    <Grid container direction='column'>

                        <Grid container direction='column'>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Nome de usuário"
                                    inputProps={{
                                        disabled: true,
                                        id: "username",
                                        value: username,
                                        autoFocus: true,
                                    }} />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Data de criação"
                                    inputProps={{
                                        disabled: true,
                                        id: "createdAt",
                                        value: createdAt,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Data da última modificação"
                                    inputProps={{
                                        disabled: true,
                                        id: "modifiedAt",
                                        value: modifiedAt,
                                    }}
                                />
                            </ItemGrid>

                        </Grid>

                    </Grid>
                </RegularCard>
                <Link to="/users">Voltar Para Lista</Link>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteUser: (id) => dispatch(deleteResource(Resources.Users, id)),
        getUser: (id) => dispatch(fetchSingleResource(Resources.Users, id))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Details));
