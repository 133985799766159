import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment';
import {
    Grid,
    Paper,
    Tabs,
    Tab
} from "@material-ui/core";

import {
    ItemGrid,
    RegularCard,
    Button,
    CustomInput,
} from "components";

import { deleteClient, fetchClient } from 'ducks/clients'

import RentalsReferenceTable from "views/Main/Rentals/RentalsReferenceTable.jsx";
import { roles } from "utils/variables";
import get from 'lodash/get';

const ItemGridMarginStyle = {
    marginTop: 24,
    marginBottom: 24
}

class Details extends Component {
    state = {
        tabPos: 0,
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        telNumber: '',
        rg: '',
        birthDate: '',
        addressLine: '',

        facebookUri: '',
        instagramUri: '',
        twitterUri: '',
    }


    componentDidMount = () => {
        const { id } = this.props.match.params;
        id && this.props.getClient(id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.match.params;
        const prevId = prevProps.match.params.id;

        if (id !== prevId)
            id && this.props.getClient(id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedClient) {
            return {
                name: nextProps.selectedClient.name || '',
                email: nextProps.selectedClient.email || '',
                cpf: nextProps.selectedClient.cpf || '',
                cellNumber: nextProps.selectedClient.cellNumber || '',
                telNumber: nextProps.selectedClient.telNumber || '',
                rg: nextProps.selectedClient.rg || '',
                birthDate: get(nextProps, 'selectedClient.birthDate') ? moment(get(nextProps, 'selectedClient.birthDate')).format('LL') : '',
                addressLine: nextProps.selectedClient.addressLine || '',
                facebookUri: nextProps.selectedClient.facebookUri || '',
                instagramUri: nextProps.selectedClient.instagramUri || '',
                twitterUri: nextProps.selectedClient.twitterUri || '',
            }
        };

        return null;
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteClient(id).then((data) => data.response.success && this.props.history.push(`/clients`))

    }

    handleRentalSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/rentals/${item.id}`)
    }


    render() {
        const { id } = this.props.match.params;
        const {
            tabPos,
            name,
            email,
            cpf,
            cellNumber,
            telNumber,
            rg,
            birthDate,
            addressLine,
            facebookUri,
            instagramUri,
            twitterUri } = this.state;

        const title = "Dados de cliente";
        const subtitle = `Você está visualizando os dados do cliente ${name}.`

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
                footer={
                    <Grid container>
                        <ItemGrid>
                            <Link to={`/clients/${id}/edit`}>
                                <Button color='primary'>
                                    Editar
                                </Button>
                            </Link>
                        </ItemGrid>
                        {this.props.userRole === roles.Admin &&
                            <ItemGrid>
                                <Button onClick={this.handleDeleteClick} color='danger'>
                                    Desativar
                            </Button>
                            </ItemGrid>
                        }
                    </Grid>
                }
            >
                <Grid container direction='column'>
                    <ItemGrid style={ItemGridMarginStyle}>
                        <Paper>
                            <Tabs
                                value={this.state.tabPos}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.handleTabChange}>

                                <Tab label="Informações" />
                                <Tab label="Locações" />
                            </Tabs>
                        </Paper>
                    </ItemGrid>

                    {tabPos === 0 ?
                        <Grid container direction='column'>
                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Nome"
                                    inputProps={{
                                        disabled: true,
                                        id: "name",
                                        value: name,
                                    }} />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Email"
                                    inputProps={{
                                        disabled: true,
                                        id: "email",
                                        value: email,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="CPF"
                                    inputProps={{
                                        disabled: true,
                                        id: "cpf",
                                        value: cpf,
                                        mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="RG"
                                    inputProps={{
                                        disabled: true,
                                        id: "rg",
                                        value: rg,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Endereço"
                                    inputProps={{
                                        disabled: true,
                                        id: "addressLine",
                                        value: addressLine,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Celular"
                                    inputProps={{
                                        disabled: true,
                                        id: "cellNumber",
                                        value: cellNumber,
                                        mask: ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
                                    }}
                                />
                            </ItemGrid>
                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Telefone"
                                    inputProps={{
                                        disabled: true,
                                        id: "telNumber",
                                        value: telNumber,
                                        mask: ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Data de nascimento"
                                    inputProps={{
                                        disabled: true,
                                        id: "birthDate",
                                        value: birthDate,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Facebook"
                                    inputProps={{
                                        disabled: true,
                                        id: "facebookUri",
                                        value: facebookUri,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Instagram"
                                    inputProps={{
                                        disabled: true,
                                        id: "instagramUri",
                                        value: instagramUri,
                                    }}
                                />
                            </ItemGrid>

                            <ItemGrid style={ItemGridMarginStyle}>
                                <CustomInput
                                    labelText="Twitter"
                                    inputProps={{
                                        disabled: true,
                                        id: "twitterUri",
                                        value: twitterUri,
                                    }}
                                />
                            </ItemGrid>


                        </Grid>
                        :
                        id &&
                        <RentalsReferenceTable
                            actionParams={`?clientId=${id}`}
                            onSelected={this.handleRentalSelected}
                            emptyMessage='Ainda não foi realizada nenhuma locação para este cliente.' />
                    }
                </Grid>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedClient: state.resources.selectedResource,
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        deleteClient: (id) => dispatch(deleteClient(id)),
        getClient: (id) => dispatch(fetchClient(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
