import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import LockOutlined from "@material-ui/icons/LockOutlined";
import People from "@material-ui/icons/People";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import image from "assets/img/profile-bg.jpg";

import { connect } from 'react-redux';
import { loginUser, loginUserSuccess } from 'ducks/auth'
import { Redirect } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames'
import { POST_SUCCESS } from "ducks/resources";
import get from 'lodash/get';

class LoginPage extends React.Component {
  state = {
    cardAnimaton: "cardHidden",
    username: '',
    password: ''
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(() => this.setState({ cardAnimaton: "" }), 700);
  }

  handleChange = event => {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    });
  };

  submitForm = (event) => {
    event.preventDefault();
    this.props.loginUser(this.state.username, this.state.password)
      .then(action => get(action, 'type') === POST_SUCCESS ? action.response : Promise.reject())
      .then(response => response && this.props.loginUserSuccess(response))
      .then(x => this.props.history.push('/'));
  }

  render() {
    const { classes, isAuthenticating, isAuthenticated } = this.props;
    const { username, password } = this.state;

    if (isAuthenticated)
      return <Redirect to='/' />

    return (
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classNames([classes[this.state.cardAnimaton], classes.form])}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Bem vindo!</h4>
                </CardHeader>
                <CardBody>

                  <CustomInput
                    labelText="Usuário"
                    formControlProps={{
                      fullWidth: true
                    }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    )}
                    inputProps={{
                      id: "username",
                      autoFocus: true,
                      value: username,
                      onChange: this.handleChange,
                    }}
                  />

                  <CustomInput
                    labelText="Senha"
                    formControlProps={{
                      fullWidth: true
                    }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <LockOutlined className={classes.inputIconsColor} />
                      </InputAdornment>
                    )}
                    inputProps={{
                      id: "password",
                      type: "password",
                      value: password,
                      onChange: this.handleChange,
                    }}
                  />

                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button onClick={this.submitForm} color="primary">
                    Entrar
                      </Button>
                </CardFooter>
                {isAuthenticating && <LinearProgress color="primary" />}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
});

const mapDispatchToProps = {
  loginUser,
  loginUserSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));
