import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import {
    Grid,
    Paper,
    Tabs,
    Tab
} from "@material-ui/core";

import {
    ItemGrid,
    RegularCard,
    Button,
    CustomInput,
} from "components";

import RentalsReferenceTable from "views/Main/Rentals/RentalsReferenceTable.jsx";
import { deleteResource, fetchSingleResource } from '../../../ducks/resources';
import { Resources } from 'utils/variables';
import Reports from './Reports';
import { roles } from '../../../utils/variables';

const ItemGridMarginStyle = {
    marginTop: 24,
    marginBottom: 24
}

class Details extends Component {
    state = {
        tabPos: 0,
        fullName: '',
        cpf: '',
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        id && this.props.getSeller(id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.match.params;
        const prevId = prevProps.match.params.id;

        if (id !== prevId)
            id && this.props.getSeller(id);
    }

    handleRentalSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/rentals/${item.id}`)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedSeller)
            return {
                fullName: nextProps.selectedSeller.fullName || '',
                cpf: nextProps.selectedSeller.cpf || '',
            };

        return null;
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteSeller(id).then((data) => data.response.success && this.props.history.push(`/sellers`))

    }

    render() {
        const { id } = this.props.match.params;
        const { fullName, cpf, tabPos } = this.state;
        const { userRole } = this.props;
        const title = "Dados de vendedora";
        const subtitle = `Você está visualizando os dados da vendedora ${fullName}.`

        return (
            <React.Fragment>
                <RegularCard
                    cardTitle={title}
                    cardSubtitle={subtitle}
                    footer={
                        <Grid container>
                            <ItemGrid>
                                <Link to={`/sellers/${id}/edit`}>
                                    <Button color='primary'>
                                        Editar
                                </Button>
                                </Link>
                            </ItemGrid>
                            <ItemGrid>
                                <Button onClick={this.handleDeleteClick} color='danger'>
                                    Excluir
                            </Button>
                            </ItemGrid>
                        </Grid>
                    }
                >
                    <Grid container direction='column'>
                        <ItemGrid style={ItemGridMarginStyle}>
                            <Paper>
                                <Tabs
                                    value={tabPos}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabChange}>

                                    <Tab label="Informações" />
                                    <Tab label="Locações" />
                                    <Tab label="Relatórios" />
                                </Tabs>
                            </Paper>
                        </ItemGrid>

                        {tabPos === 0 &&
                            <Grid container direction='column'>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Nome completo"
                                        inputProps={{
                                            disabled: true,
                                            id: "fullName",
                                            value: fullName,
                                            autoFocus: true,
                                        }} />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="CPF"
                                        inputProps={{
                                            disabled: true,
                                            id: "cpf",
                                            value: cpf,
                                            mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                        }}
                                    />
                                </ItemGrid>
                            </Grid>
                        }
                        {tabPos === 1 &&
                            (id && <RentalsReferenceTable actionParams={`?sellerId=${id}`} onSelected={this.handleRentalSelected}
                                emptyMessage='Essa vendedora ainda não realizou nenhuma locação.' />)
                        }

                        {(tabPos === 2 && userRole === roles.Admin) &&

                            (id && <Reports resource={Resources.Sellers} resourceId={id} />)
                        }
                    </Grid>
                </RegularCard>
                <Link to="/sellers">Voltar Para Lista</Link>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedSeller: state.resources.selectedResource,
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        deleteSeller: (id) => dispatch(deleteResource(Resources.Sellers, id)),
        getSeller: (id) => dispatch(fetchSingleResource(Resources.Sellers, id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));