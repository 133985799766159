import React from "react";
import { MenuItem, Select, FormControl, InputLabel, Grid } from "@material-ui/core";
import { Table, ItemGrid, FilterPanel } from "components";
import moment from 'moment';
import { PaymentForm } from "../../../utils";
import { DatePicker } from 'material-ui-pickers';

class PaymentsTable extends React.Component {
    state = {
        filter: {
            paymentForm: '',
            date: null
        }
    }

    handleFilterChange = event => {
        const id = event.target.id || event.target.name;
        const value = event.target.value;

        this.setState((prevState) => ({
            filter: {
                ...prevState.filter,
                [id]: value
            },
        }));
    };

    render() {
        const { filter } = this.state
        let { data } = this.props;

        if (filter.dateFrom)
            data = data.filter(x => moment(x.date).isSameOrAfter(filter.dateFrom));
        if (filter.dateTo)
            data = data.filter(x => moment(x.date).isSameOrBefore(filter.dateTo));
        if (Number.isInteger(filter.paymentForm))
            data = data.filter(x => x.paymentForm === filter.paymentForm);

        const cells = [
            { id: 'value', label: "Valor", format: x => `R$${x}`  },
            { id: 'paymentForm', label: "Forma de Pagamento", format: x => PaymentForm[x] },
            { id: 'date', label: "Data", format: x => moment(x).format('LL') },
        ];

        return (
            <ItemGrid xs={12} sm={12} md={12}>
                <FilterPanel title='Filtrar Lista'>
                    <Grid container spacing={16}>

                        <DatePicker
                            value={this.state.filter.dateFrom}
                            onChange={(date) => this.handleFilterChange({ target: { id: 'dateFrom', value: date } })}
                            label="De"
                            format="LL"
                        />
                        <DatePicker
                            value={this.state.filter.dateTo}
                            onChange={(date) => this.handleFilterChange({ target: { id: 'dateTo', value: date } })}
                            label="Até"
                            format="LL"
                        />

                        <FormControl style={{ minWidth: 120, marginTop: 30 }} fullWidth>
                            <InputLabel htmlFor="paymentForm">Forma de Pagamento</InputLabel>

                            <Select
                                value={this.state.filter.paymentForm}
                                onChange={this.handleFilterChange}
                                inputProps={{
                                    id: 'paymentForm',
                                    name: 'paymentForm',
                                }}
                            >
                                <MenuItem value="">
                                    <em>Todos</em>
                                </MenuItem>
                                <MenuItem value={0}>Dinheiro</MenuItem>
                                <MenuItem value={1}>Débito</MenuItem>
                                <MenuItem value={2}>Crédito</MenuItem>
                                <MenuItem value={3}>Transferência</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </FilterPanel>

                <Table
                    {...this.props}
                    data={data}
                    cells={cells}
                    onItemSelected={this.props.handleItemSelected}
                />
            </ItemGrid>
        )
    }
}

export default PaymentsTable;