import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'

import {
    Grid,
    Paper,
    Tabs,
    Tab,
    Select,
    MenuItem
} from "@material-ui/core";

import {
    ItemGrid,
    RegularCard,
    Button,
    CustomInput,
} from "components";
import { dateToInput } from "utils";

import { deleteProduct, fetchProduct } from 'ducks/products'
import RentalsReferenceTable from "views/Main/Rentals/RentalsReferenceTable.jsx";
import { roles } from "utils/variables";
import { ImageGallery } from '../../../components';

const ItemGridMarginStyle = {
    marginTop: 24,
    marginBottom: 24
}

class Details extends Component {
    state = {
        tabPos: 0,
        code: '',
        name: '',
        description: '',
        color: '',
        rentalValue: 0,
        originalValue: 0,
        acquisitionValue: 0,
        acquisitionDate: '',
        brand: '',
        category: 0,
        size: 0,
        images: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        id && this.props.getProduct(id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { id } = this.props.match.params;
        const prevId = prevProps.match.params.id;

        if (id !== prevId)
            id && this.props.getProduct(id);
    }

    handleRentalSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/rentals/${item.id}`)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedProduct)
            return {
                code: nextProps.selectedProduct.code || '',
                name: nextProps.selectedProduct.name || '',
                description: nextProps.selectedProduct.description || '',
                color: nextProps.selectedProduct.color || '',
                rentalValue: nextProps.selectedProduct.rentalValue || 0,
                originalValue: nextProps.selectedProduct.originalValue || 0,
                acquisitionDate: dateToInput(nextProps.selectedProduct.acquisitionDate) || '',
                acquisitionValue: nextProps.selectedProduct.acquisitionValue || 0,
                brand: nextProps.selectedProduct.brand || '',
                category: nextProps.selectedProduct.category || 0,
                size: nextProps.selectedProduct.size || 0,
                images: nextProps.selectedProduct.images || [],
            };

        return null;
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    handleDeleteClick = () => {
        const { id } = this.props.match.params;
        this.props.deleteProduct(id).then((data) => data.response.success && this.props.history.push(`/products`))

    }

    render() {
        const { id } = this.props.match.params;
        const { tabPos, code, name, color, description, originalValue, rentalValue, acquisitionValue, acquisitionDate, brand, category, size, images } = this.state;
        const title = "Dados de produto";
        const subtitle = `Você está visualizando os dados do produto ${name}.`

        return (
            <React.Fragment>

                <RegularCard
                    cardTitle={title}
                    cardSubtitle={subtitle}
                    footer={
                        <Grid container>
                            <ItemGrid>
                                <Link to={`/products/${id}/edit`}>
                                    <Button color='primary'>
                                        Editar
                                </Button>
                                </Link>
                            </ItemGrid>
                            {this.props.userRole === roles.Admin &&
                                <ItemGrid>
                                    <Button onClick={this.handleDeleteClick} color='danger'>
                                        Desativar
                            </Button>
                                </ItemGrid>
                            }
                        </Grid>
                    }
                >
                    <Grid container direction='column'>
                        <ItemGrid style={ItemGridMarginStyle}>
                            <Paper>
                                <Tabs
                                    value={this.state.tabPos}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabChange}>

                                    <Tab label="Informações" />
                                    <Tab label="Locações" />
                                    <Tab label="Galeria" />
                                </Tabs>
                            </Paper>
                        </ItemGrid>

                        {tabPos === 0 ?
                            <Grid container direction='column'>
                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Código"
                                        inputProps={{
                                            id: "code",
                                            value: code,
                                            disabled: true
                                        }} />
                                </ItemGrid>
                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Nome referência"
                                        inputProps={{
                                            id: "name",
                                            value: name,
                                            disabled: true
                                        }} />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Cor"
                                        inputProps={{
                                            disabled: true,
                                            id: "color",
                                            value: color,
                                        }}
                                    />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <Select
                                        value={category}
                                        inputProps={{
                                            disabled: true,
                                            name: 'category',
                                            id: 'category',
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Categoria</em>
                                        </MenuItem>
                                        <MenuItem value={1}>Curto</MenuItem>
                                        <MenuItem value={2}>Longo</MenuItem>
                                    </Select>
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <Select
                                        value={size}
                                        inputProps={{
                                            disabled: true,
                                            name: 'size',
                                            id: 'size',
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Tamanho</em>
                                        </MenuItem>

                                        <MenuItem value={34}>34</MenuItem>
                                        <MenuItem value={36}>36</MenuItem>
                                        <MenuItem value={38}>38</MenuItem>
                                        <MenuItem value={40}>40</MenuItem>
                                        <MenuItem value={42}>42</MenuItem>
                                        <MenuItem value={44}>44</MenuItem>
                                        <MenuItem value={46}>46</MenuItem>
                                        <MenuItem value={48}>48</MenuItem>
                                    </Select>
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Valor da locação"
                                        type="number"
                                        inputProps={{
                                            disabled: true,
                                            id: "rentalValue",
                                            value: rentalValue,
                                        }}
                                    />
                                </ItemGrid>

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Valor original"
                                        type="number"
                                        inputProps={{
                                            disabled: true,
                                            id: "originalValue",
                                            value: originalValue,
                                        }}
                                    />
                                </ItemGrid>

                                {this.props.userRole === roles.Admin &&
                                    <div>

                                        <ItemGrid style={ItemGridMarginStyle}>
                                            <CustomInput
                                                labelText="Data de aquisição"
                                                inputProps={{
                                                    disabled: true,
                                                    type: "date",
                                                    id: "acquisitionDate",
                                                    value: acquisitionDate,
                                                }}
                                            />
                                        </ItemGrid>

                                        <ItemGrid style={ItemGridMarginStyle}>
                                            <CustomInput
                                                labelText="Valor de aquisição"
                                                type="number"
                                                inputProps={{
                                                    disabled: true,
                                                    id: "acquisitionValue",
                                                    value: acquisitionValue,
                                                }}
                                            />
                                        </ItemGrid>

                                        <ItemGrid style={ItemGridMarginStyle}>
                                            <CustomInput
                                                labelText="Marca"
                                                inputProps={{
                                                    disabled: true,
                                                    id: "brand",
                                                    value: brand,
                                                }}
                                            />
                                        </ItemGrid>
                                    </div>
                                }

                                <ItemGrid style={ItemGridMarginStyle}>
                                    <CustomInput
                                        labelText="Descrição"
                                        multiline
                                        inputProps={{
                                            disabled: true,
                                            id: "description",
                                            value: description,
                                        }}
                                    />
                                </ItemGrid>


                            </Grid>
                            :
                            tabPos === 1 ?
                                id && <RentalsReferenceTable actionParams={`?productId=${id}`} onSelected={this.handleRentalSelected}
                                    emptyMessage='Ainda não foi realizada nenhuma locação para este produto.' />
                                :
                                <ItemGrid style={ItemGridMarginStyle} xs={12} sm={12} md={12}>
                                    <ImageGallery images={images} allowChange={false} />
                                </ItemGrid>}
                    </Grid>
                </RegularCard>
                <Link to="/products">Voltar Para Lista</Link>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedProduct: state.resources.selectedResource,
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        deleteProduct: (id) => dispatch(deleteProduct(id)),
        getProduct: (id) => dispatch(fetchProduct(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
