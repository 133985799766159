import React, { Component } from "react";
import { Button } from "components";
import { fetchAllRentalPayments, postPayment, putPayment, deletePayment } from "ducks/payments";
import PaymentsTable from "../Financial/PaymentsTable";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
    withMobileDialog, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Grid, IconButton, Paper, Typography, withStyles, TextField
} from "@material-ui/core";

import get from 'lodash/get'
import FormNumericInput from "../../../components/CustomInput/FormNumericInput";
import { DatePicker } from 'material-ui-pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import { roles } from "utils/variables";
import moment from "moment";

const NewDialog = withMobileDialog()((props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={props.fullScreen}
        >
            <DialogTitle id="form-dialog-title">{props.operation === "new" ? 'Novo Pagamento' : 'Alterar Pagamento'}</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={24}>
                    <FormNumericInput
                        label="Valor Pago"
                        id="value"
                        value={get(props, 'selected.value', '')}
                        onChange={event => props.handleFieldChange(event)}
                    />

                    <TextField
                        id="paymentForm"
                        name="paymentForm"
                        select
                        label="Forma de Pagamento"
                        margin="normal"
                        variant="outlined"
                        value={get(props, 'selected.paymentForm', '')}
                        onChange={event => props.handleFieldChange(event)}
                    >
                        <MenuItem value={0}>Dinheiro</MenuItem>
                        <MenuItem value={1}>Cartão de débito</MenuItem>
                        <MenuItem value={2}>Cartão de crédito</MenuItem>
                        <MenuItem value={3}>Transferência bancária</MenuItem>
                    </TextField>

                    {props.userRole === roles.Admin &&
                        <DatePicker
                            clearable
                            value={get(props, 'selected.date', '')}
                            onChange={(date) => props.handleDateChange('date', date)}
                            label="Data do Pagamento"
                            format="LL"
                        />
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.operation === "edit" &&
                    < IconButton onClick={(e) => props.handleDelete(e)} aria-label="Delete">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                }
                <Button onClick={props.handleClose} color="transparent">
                    Voltar
            </Button>
                <Button onClick={(e) => props.handleSubmit(e)} color="primary">
                    Salvar
            </Button>
            </DialogActions>
        </Dialog >
    )
})

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class RentalPayments extends Component {
    state = {
        selected: { paymentForm: '', date: moment() },
        dialogOpen: false,
        operation: 'new',
    }

    componentDidMount() {
        console.log('mount')
        this.loadData();
    }

    loadData = () => {
        let rentalId = get(this.props, 'selectedRental.id');
        rentalId && this.props.getPayments({rentalId});
    }

    handleChange = event => {
        const { id, name, value } = event.target

        this.setState(prevState => (
            { selected: { ...prevState.selected, [id || name]: value } }
        ))
    };

    handleDateChange = (field, date) => {
        this.setState(prevState => (
            { selected: { ...prevState.selected, [field]: date } }
        ))
    };

    handleItemSelected = (event, item) => {
        this.setState({ dialogOpen: true, operation: 'edit', selected: item })
    };

    handleDialogOpen = () => {
        this.setState({ dialogOpen: true, operation: 'new' });
    };

    handleClose = () => {
        this.setState({ dialogOpen: false, selected: {} })
    }

    handleDelete = () => {
        this.props.deletePayment(this.state.selected.id).then(r => {
            if (get(r, 'response.success')) {
                this.handleClose();
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.selectedRental.id;
        const selected = this.state.selected
        const payment = { ...selected, value: Number(selected.value), rentalId: id }

        if (this.state.operation === "new")
            this.props.postPayment(payment).then(r => {
                if (get(r, 'response.success')) {
                    const id = get(r, 'response.data.id')
                    id && this.handleClose();
                }
            });
        else
            this.props.putPayment(selected.id, payment).then(r => {
                if (get(r, 'response.success')) {
                    const id = get(r, 'response.data.id')
                    id && this.handleClose();
                }
            });
    };

    render() {
        const { payments } = this.props;
        const rentalValue = get(this.props, 'selectedRental.product.rentalValue') || 0;
        const discount = get(this.props, 'selectedRental.discount') || 0;
        const additional = get(this.props, 'selectedRental.additional') || 0;

        const totalValue = rentalValue - discount + additional;

        let paid = 0;
        let remaining = 0;

        if (Array.isArray(payments)) {
            paid = payments.map(x => x.value).reduce((prev, current) => prev + current, 0);
            remaining = totalValue - paid;
        }

        return (
            <Grid>
                <Paper className={this.props.classes.root} elevation={1}>
                    <Typography variant="h5" gutterBottom align="center">
                        {`Valor da locação: R$${totalValue}`}
                    </Typography>
                    <Typography variant="h5" gutterBottom align="center">
                        {`Total pago: R$${paid}`}
                    </Typography>
                    <Typography variant="h5" gutterBottom align="center" color="primary">
                        {`Saldo a pagar: R$${remaining}`}
                    </Typography>
                </Paper>

                <Button onClick={this.handleDialogOpen} color='warning'>
                    Novo Pagamento
                </Button>

                {payments &&
                    <PaymentsTable onRefresh={this.loadData}
                        fixed={payments.length > 10}
                        data={payments}
                        handleItemSelected={this.handleItemSelected}
                        showStatus />
                }
                <NewDialog operation={this.state.operation}
                    selected={this.state.selected}
                    handleDelete={this.handleDelete}
                    handleFieldChange={this.handleChange}
                    handleDateChange={this.handleDateChange}
                    open={this.state.dialogOpen}
                    handleClose={this.handleClose}
                    handleSubmit={this.handleSubmit}
                    userRole={this.props.userRole} />

            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    payments: state.resources['payments'],
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        getPayments: rentalId => dispatch(fetchAllRentalPayments(rentalId)),
        postPayment: payment => dispatch(postPayment(payment)),
        putPayment: (paymentId, payment) => dispatch(putPayment(paymentId, payment)),
        deletePayment: paymentId => dispatch(deletePayment(paymentId)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RentalPayments)));
