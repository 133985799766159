import React from "react";
import { TextField, Grid, Paper } from "@material-ui/core";
import { Table, ItemGrid, FilterPanel } from "components";
import moment from "moment";
import { DatePicker } from 'material-ui-pickers';
import AsTable from "../../../components/HOC/AsTable";
import { Link } from 'react-router-dom'
import { Button } from "../../../components";

const EventsTable = props => {
    const { handleFilterChange, filter } = props;
    let { data } = props;

    if (filter.dateFrom)
        data = data.filter(x => moment(x.date).isSameOrAfter(filter.dateFrom, 'day'));
    if (filter.dateTo)
        data = data.filter(x => moment(x.date).isSameOrBefore(filter.dateTo, 'day'));

    if (filter.name)
        data = data.filter(x => x.name && x.name.toLowerCase().trim().includes(filter.name.toLowerCase().trim()));

    if (filter.local)
        data = data.filter(x => x.local && x.local.toLowerCase().trim().includes(filter.local.toLowerCase().trim()));

    return (
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12} style={{
                position: 'sticky',
                top: '0',
                padding: '16px',
                zIndex: 50
            }}>
                <Paper style={{ padding: '16px' }}>
                    {props.embeddedButton &&
                        <Link to='/events/create'>
                            <Button color='primary'>
                                Novo evento
                            </Button>
                        </Link>
                    }

                        <FilterPanel title='Filtrar Lista'>
                        <DatePicker
                            disableOpenOnEnter
                            value={filter.dateFrom}
                            keyboard
                            onChange={(date) => handleFilterChange({ target: { id: 'dateFrom', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="De"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />
                        <DatePicker
                            disableOpenOnEnter
                            keyboard
                            value={filter.dateTo}
                            onChange={(date) => handleFilterChange({ target: { id: 'dateTo', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Até"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />

                        <TextField
                            id='name'
                            label='Nome'
                            value={filter.name}
                            onChange={handleFilterChange} />

                        <TextField
                            id='local'
                            label='Local'
                            value={filter.local}
                            onChange={handleFilterChange} />
                    </FilterPanel>
                </Paper>
            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={12}>
                <Table
                    {...props}
                    data={data}
                    cells={[{ id: 'name', label: "Nome" },
                    { id: 'local', label: "Local" },
                    { id: 'date', label: "Data", format: (x) => moment(x).format('DD/MM/YYYY') }]} />
            </ItemGrid>
        </Grid>

    )
}


export default AsTable(EventsTable, {
    name: '',
    local: '',
    dateFrom: moment(),
    dateTo: null,
});