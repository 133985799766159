import { CALL_API } from '../utils/apiMiddleware'
import * as ActionTypes from './resources'

export const fetchAllPayments = () => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/payments/`
    }
})

export const fetchAllRentalPayments = (params) => {
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
    return {
        [CALL_API]: {
            types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
            endpoint: `/payments/?${query}`
        }
    }
}

export const fetchPayment = (id) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_SINGLE_REQUEST, ActionTypes.GET_SINGLE_SUCCESS, ActionTypes.GET_SINGLE_FAILURE],
            endpoint: `/payments/${id}/`
        }
    })
}

export const postPayment = (payment) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/payments/`,
            method: 'POST',
            body: payment
        }
    })
}

export const putPayment = (paymentId, payment) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.PUT_REQUEST, ActionTypes.PUT_SUCCESS, ActionTypes.PUT_FAILURE],
            endpoint: `/payments/${paymentId}`,
            method: 'PUT',
            body: payment
        }
    })
}

export const deletePayment = paymentId => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.DELETE_REQUEST, ActionTypes.DELETE_SUCCESS, ActionTypes.DELETE_FAILURE],
            endpoint: `/payments/${paymentId}`,
            method: 'DELETE'
        }
    })
}