import { CALL_API } from '../utils/apiMiddleware'
import qs from 'qs'
import * as ActionTypes from './resources'

export const fetchAllDates = () => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/agenda/`
    }
})

export const fetchAvailableAdjustmentTimes = (date) => {
    const query = qs.stringify({ date, type: 0 });
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
            endpoint: `/agenda/Available?${query}`,
            resourceName: 'availableAdjustmentTimes'
        }
    })
}

export const fetchAvailableDeliveryTimes = (date) => {
    const query = qs.stringify({ date, type: 1 });
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
            endpoint: `/agenda/Available?${query}`,
            resourceName: 'availableDeliveryTimes'
        }
    })
}