import React from "react";
import { Switch, Route } from "react-router-dom";

import List from './List';
import Form from './Form';

const Clients = () =>
    (
        <Switch>
            <Route path='/clients/create' component={Form} />
            <Route path='/clients/:id/edit' exact component={Form} />
            <Route path='/clients' component={List} />
        </Switch>
    );

export default Clients;