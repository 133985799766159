import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { store, history } from './store'
import 'moment/locale/pt-br';
import "assets/css/material-dashboard-react.css";
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import App from "./views/App";

moment.locale('pt-br');
moment.fn.toJSON = function () { return this.format(); }

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        moment={moment}
        locale="pt-br"
      >
        <App />
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
