import React from "react";
import NumberFormat from 'react-number-format';
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const FormNumericInput = ({ id, label, value, onChange, ...props }) => {
    return (
        <NumberFormat
            customInput={TextField}
            id={id}
            label={label}
            value={value}
            onValueChange={(value) => onChange({ target: { id, value: value.floatValue } })}
            {...props}
        />
    )
}

FormNumericInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func
};

FormNumericInput.defaultProps = {
    value: 0,
    fullWidth: true,
    prefix: 'R$',
    fixedDecimalScale: true,
    decimalScale: 2,
    thousandSeparator: '.',
    decimalSeparator: ',',
    variant: "outlined",
    margin: "normal",
    onChange: (e) => { }
};

export default FormNumericInput;