import { updateObjectInArray } from "../utils";
import { CALL_API } from '../utils/apiMiddleware';
import qs from 'qs';

export const GET_ALL_REQUEST = 'dmu-manager-web/resources/GET_ALL_REQUEST'
export const GET_ALL_SUCCESS = 'dmu-manager-web/resources/GET_ALL_SUCCESS'
export const GET_ALL_FAILURE = 'dmu-manager-web/resources/GET_ALL_FAILURE'

export const GET_SINGLE_REQUEST = 'dmu-manager-web/resources/GET_SINGLE_REQUEST'
export const GET_SINGLE_SUCCESS = 'dmu-manager-web/resources/GET_SINGLE_SUCCESS'
export const GET_SINGLE_FAILURE = 'dmu-manager-web/resources/GET_SINGLE_FAILURE'

export const POST_REQUEST = 'dmu-manager-web/resources/POST_REQUEST'
export const POST_SUCCESS = 'dmu-manager-web/resources/POST_SUCCESS'
export const POST_FAILURE = 'dmu-manager-web/resources/POST_FAILURE'

export const PUT_REQUEST = 'dmu-manager-web/resources/PUT_REQUEST'
export const PUT_SUCCESS = 'dmu-manager-web/resources/PUT_SUCCESS'
export const PUT_FAILURE = 'dmu-manager-web/resources/PUT_FAILURE'

export const DELETE_REQUEST = 'dmu-manager-web/resources/DELETE_REQUEST'
export const DELETE_SUCCESS = 'dmu-manager-web/resources/DELETE_SUCCESS'
export const DELETE_FAILURE = 'dmu-manager-web/resources/DELETE_FAILURE'

const initialState = {
    isFetching: false,
    account: {},
    selectedResource: {},
    clients: [],
    products: [],
    events: [],
    sellers: [],
    rentals: []
};

export default function reducer(state = initialState, action) {
    const { type, resource, message, response } = action;

    switch (type) {
        case GET_SINGLE_REQUEST:
            return {
                ...state,
                isFetching: true,
                selectedResource: {}
            };
        case GET_ALL_REQUEST:
        case POST_REQUEST:
        case PUT_REQUEST:
        case DELETE_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case GET_SINGLE_FAILURE:
        case GET_ALL_FAILURE:
        case POST_FAILURE:
        case PUT_FAILURE:
        case DELETE_FAILURE:
            return {
                ...state,
                message: message,
                messageShown: false,
                isFetching: false,
            };

        case GET_SINGLE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                selectedResource: response
            }
        case GET_ALL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                [resource]: response
            }
        case POST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: message,
                messageShown: false,
                [resource]: updateObjectInArray(state[resource], response.data)
            }
        case PUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: message,
                selectedResource: { ...state.selectedResource, ...response.data },
                messageShown: false,
                [resource]: updateObjectInArray(state[resource], response.data)

            }
        case DELETE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                message: message,
                messageShown: false,
                [resource]: state[resource].filter((item, index) => item.id !== response.data.id)
            }
        default:
            return state;
    }
}

export const fetchAllResources = (resource) => ({
    [CALL_API]: {
        types: [GET_ALL_REQUEST, GET_ALL_SUCCESS, GET_ALL_FAILURE],
        endpoint: `/${resource}/`
    }
})

export const fetchSingleResource = (resource, id) => {
    return ({
        [CALL_API]: {
            types: [GET_SINGLE_REQUEST, GET_SINGLE_SUCCESS, GET_SINGLE_FAILURE],
            endpoint: `/${resource}/${id}`
        }
    })
}

export const fetchSingleResourceByQs = (resource, id) => (queryObj) => {
    const query = qs.stringify(queryObj);
    return ({
        [CALL_API]: {
            types: [GET_SINGLE_REQUEST, GET_SINGLE_SUCCESS, GET_SINGLE_FAILURE],
            endpoint: `/${resource}/${id}?${query}`
        }
    })
}

export const postResource = (resource, body) => {
    return ({
        [CALL_API]: {
            types: [POST_REQUEST, POST_SUCCESS, POST_FAILURE],
            endpoint: `/${resource}/`,
            method: 'POST',
            body
        }
    })
}

export const putResource = (resource, id, body) => {
    return ({
        [CALL_API]: {
            types: [PUT_REQUEST, PUT_SUCCESS, PUT_FAILURE],
            endpoint: `/${resource}/${id}`,
            method: 'PUT',
            body: body
        }
    })
}

export const deleteResource = (resource, id) => {
    return ({
        [CALL_API]: {
            types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
            endpoint: `/${resource}/${id}`,
            method: 'DELETE'
        }
    })
}