import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/icons/Menu";
import {
  withStyles,
  AppBar,
  Toolbar,
  IconButton,
  Hidden
} from "@material-ui/core";
import cx from "classnames";

import headerStyle from "assets/jss/styles/headerStyle.jsx";

function Header({ ...props }) {

  const { classes, color } = props;

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });


  return (
    <Hidden mdUp>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>

          {/* <Hidden smDown implementation="css">
          <HeaderLinks />
        </Hidden> */}

          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
