import React, { Component } from 'react';
import { Grid, MenuItem, Select, InputLabel, FormHelperText, FormControl } from "@material-ui/core";
import {
    RegularCard,
    Button
} from "components";
import validator from "utils/validator";

import { connect } from 'react-redux';
import { postProduct, putProduct, fetchProduct } from 'ducks/products';
import { withRouter, Link } from 'react-router-dom';
import { roles } from "utils/variables";
import { FormInput, InputsContainer, ImageGallery, FormNumericInput } from 'components';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import get from 'lodash/get';

class Form extends Component {
    state = {
        name: '',
        description: '',
        color: '',
        rentalValue: 0,
        originalValue: 0,
        acquisitionValue: 0,
        acquisitionDate: moment(),
        brand: '',
        category: 0,
        size: 0,
        files: [],
        imgs: [],
        validations: {}
    }

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        id && this.props.getProduct(id).then(x => {
            const product = x.response;
            this.setState({
                name: product.name,
                description: product.description,
                color: product.color,
                rentalValue: product.rentalValue,
                originalValue: product.originalValue,
                acquisitionDate: moment(product.acquisitionDate),
                acquisitionValue: product.acquisitionValue,
                brand: product.brand,
                category: product.category,
                size: product.size,
                imgs: product.images,
            })
        });
    }

    handleChange = event => {
        const { id, value, name } = event.target
        this.setState({
            [id || name]: value,
        }, () => {
            if (name === 'size' || name === 'category') {
                this.validate(name)
            }
        });
    };

    validate = id => {
        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            }
        }));
    }
    handleFocusChange = event => {
        const { id } = event.target;
        this.validate(id);
    };

    onImageAdd = files => {
        const arr = files.map(f => ({ src: f.preview, thumbnail: f.preview, local: true }));
        this.setState((prev) => ({
            files: [...prev.files, ...files],
            imgs: [...prev.imgs, ...arr]
        }));
    }

    onImageRemove = img => {
        this.setState(prevState => ({
            files: prevState.files.filter(x => img.index !== prevState.files.indexOf(x)),
            imgs: prevState.imgs.filter(x => img.index !== prevState.imgs.indexOf(x))
        }), () => console.log(this.state.files, this.state.imgs))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (prevState) => ({ validations: validator(prevState, constraints) }),
            () => {
                if (this.state.validations !== true) {
                    return;
                } else {
                    const { id } = this.props.match.params;
                    const {
                        acquisitionDate,
                        acquisitionValue,
                        brand,
                        category,
                        color,
                        description,
                        name,
                        originalValue,
                        rentalValue,
                        size,
                        imgs,
                        files
                    } = this.state;

                    const product = {
                        acquisitionDate: new Date(acquisitionDate),
                        acquisitionValue,
                        brand,
                        category,
                        color,
                        description,
                        name,
                        originalValue,
                        rentalValue,
                        size,
                    };

                    if (id) {
                        this.props.putProduct(id, product, files, imgs.filter(x => x.local !== true), this.props.history);
                    } else {
                        this.props.postProduct(product, files, this.props.history);
                    }
                }
            });
    };

    render() {
        const { id } = this.props.match.params;
        const title = id ? `Editar Produto` : "Novo Produto"
        const { validations, name, color, description, originalValue, rentalValue, acquisitionValue, acquisitionDate, brand, category, size } = this.state;
        const subtitle = id ? `Você está editando os dados do produto ${this.props.selectedProduct.name}.` : "Insira os dados do novo produto."

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}
            >
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <InputsContainer>
                            <FormInput
                                autoFocus
                                label="Nome referência"
                                errors={validations['name']}
                                id="name"
                                value={name}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <FormInput
                                label="Cor"
                                errors={validations['color']}
                                id="color"
                                value={color}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="category">Categoria</InputLabel>
                                    <Select
                                        value={category}
                                        onChange={this.handleChange}
                                        onClose={() => this.handleSelectClose('category')}
                                        inputProps={{
                                            name: 'category',
                                            id: 'category',
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Categoria</em>
                                        </MenuItem>
                                        <MenuItem value={1}>Curto</MenuItem>
                                        <MenuItem value={2}>Longo</MenuItem>
                                    </Select>
                                    <FormHelperText error>{validations['category']}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="size">Tamanho</InputLabel>
                                    <Select
                                        value={size}
                                        onChange={this.handleChange}
                                        onClose={() => console.log('size')}
                                        inputProps={{
                                            name: 'size',
                                            id: 'size',
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Tamanho</em>
                                        </MenuItem>

                                        <MenuItem value={34}>34</MenuItem>
                                        <MenuItem value={36}>36</MenuItem>
                                        <MenuItem value={38}>38</MenuItem>
                                        <MenuItem value={40}>40</MenuItem>
                                        <MenuItem value={42}>42</MenuItem>
                                        <MenuItem value={44}>44</MenuItem>
                                        <MenuItem value={46}>46</MenuItem>
                                        <MenuItem value={48}>48</MenuItem>
                                    </Select>
                                    <FormHelperText error>{validations['size']}</FormHelperText>
                                </FormControl>

                            </Grid>

                            <FormNumericInput
                                label="Valor da locação"
                                errors={validations['rentalValue']}
                                id="rentalValue"
                                value={rentalValue}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <FormNumericInput
                                label="Valor original"
                                errors={validations['originalValue']}
                                id="originalValue"
                                value={originalValue}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            {this.props.userRole === roles.Admin &&
                                <React.Fragment>
                                    <Grid item>
                                        <DatePicker
                                            fullWidth={true}
                                            value={acquisitionDate}
                                            onChange={(date) => this.handleChange({ target: { id: 'acquisitionDate', value: date } })}
                                            label="Data de aquisição"
                                            format="LL"
                                        />
                                    </Grid>

                                    <FormNumericInput
                                        label="Valor de aquisição"
                                        errors={validations['acquisitionValue']}
                                        id="acquisitionValue"
                                        value={acquisitionValue}
                                        onChange={this.handleChange}
                                        onBlur={this.handleFocusChange}
                                    />

                                    <FormInput
                                        optional
                                        label="Marca"
                                        errors={validations['brand']}
                                        id="brand"
                                        value={brand}
                                        onChange={this.handleChange}
                                        onBlur={this.handleFocusChange}
                                    />
                                </React.Fragment>
                            }

                            <FormInput
                                optional
                                label="Descrição"
                                errors={validations['description']}
                                multiline
                                id="description"
                                value={description}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <Grid item>
                                <ImageGallery images={this.state.imgs} onAdd={this.onImageAdd} onRemove={this.onImageRemove} />
                            </Grid>
                        </InputsContainer>

                        <Button color="primary" type='submit' style={{ marginTop: 48 }}>Salvar Produto</Button>
                        <Link to="/products" style={{ alignSelf: "flex-start" }}>Voltar Para Lista</Link>
                    </Grid>
                </form>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedProduct: state.resources.selectedResource,
    userRole: state.auth.userRole
});

function mapDispatchToProps(dispatch) {
    return {
        putProduct: (productId, product, files, imgs, history) => dispatch(putProduct(productId, product, files, imgs, history)),
        postProduct: (product, files, history) => dispatch(postProduct(product, files, history)),
        getProduct: (id) => dispatch(fetchProduct(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));

const constraints = {
    name: {
        presence: { message: "Por favor, informe o nome de referência do produto.", allowEmpty: false },
        length: {
            minimum: 3,
            maximum: 300,
            tooShort: "O nome precisa ter pelo menos 3 caracteres",
            tooLong: "O nome informado é muito longo"
        }
    },

    category: {
        numericality: { greaterThanOrEqualTo: 1, notGreaterThanOrEqualTo: "Por favor, informe a categoria do produto." }
    },

    size: {
        numericality: { greaterThanOrEqualTo: 1, notGreaterThanOrEqualTo: "Por favor, informe o tamanho do produto." }
    },

    rentalValue: {
        numericality: { greaterThanOrEqualTo: 1, notGreaterThanOrEqualTo: "Por favor, informe o valor de locação do produto." }
    },

    originalValue: {
        numericality: { greaterThanOrEqualTo: 1, notGreaterThanOrEqualTo: "Por favor, informe o valor original do produto." }
    },

    description: {
        length: {
            maximum: 2000,
            tooLong: "A descrição informada é muito longa"
        }
    },
    color: {
        presence: { message: "Por favor, informe a cor do produto.", allowEmpty: false },
        length: {
            minimum: 2,
            maximum: 100,
            tooShort: "A cor precisa ter pelo menos 2 caracteres",
            tooLong: "O nome informado é muito longo"
        }
    },
};