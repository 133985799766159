const constraints = {
    name: {
        presence: { message: "Por favor, informe o nome do cliente.", allowEmpty: false },
        length: {
            minimum: 3,
            maximum: 300,
            tooShort: "O nome precisa ter pelo menos 3 caracteres",
            tooLong: "O nome informado é muito longo"
        }
    },

    cpf: {
        presence: { message: "Por favor, informe o CPF.", allowEmpty: false },
        format: {
            pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
            message: "Informe um CPF válido"
        }
    },

    addressCode: {
        presence: { message: "Por favor, informe o CEP do cliente.", allowEmpty: false },
        format: {
            pattern: /^\d{5}-\d{3}$/,
            message: "Informe um CEP válido"
        }
    },

    addressLine: {
        presence: { message: "Por favor, informe o endereço do cliente.", allowEmpty: false },
    },

    cellNumber: {
        presence: { message: "Por favor, informe o número de celular.", allowEmpty: false },
    },

    email: {
        email: {
            message: "Infome um email válido."
        }
    },
};

export default constraints;