import React from "react";
import { TextField, MenuItem, Select, Grid, InputLabel, FormControl, Paper } from "@material-ui/core";
import { Table, ItemGrid, FilterPanel } from "components";
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { RentalStatus, NormalizeString } from "../../../utils";
import { roles } from "utils/variables";
import { connect } from 'react-redux';
import AsTable from "../../../components/HOC/AsTable";
import has from 'lodash/has'
import { Link } from 'react-router-dom'
import { Button } from "../../../components";

const statusColorPicker = (item) => {
    if (!has(item, 'status'))
        return '';

    switch (item.status) {
        case 1:
            return '#FFF4D8';
        case 3:
            return '#D5E0F5';
        case 4:
        case 5:
            return '#D5F2C9';
        case 6:
            return '#FFB2B2';
        default:
            return '';
    }
}

const RentalsTable = props => {
    const { handleFilterChange, filter, userRole } = props;
    let { data } = props;

    if (filter.dateFromEvent)
        data = data.filter(x => moment(x.date).isSameOrAfter(filter.dateFromEvent, 'day'));
    if (filter.dateToEvent)
        data = data.filter(x => moment(x.date).isSameOrBefore(filter.dateToEvent, 'day'));

    if (filter.dateFrom)
        data = data.filter(x => moment(x.createdAt).isSameOrAfter(filter.dateFrom, 'day'));
    if (filter.dateTo)
        data = data.filter(x => moment(x.createdAt).isSameOrBefore(filter.dateTo, 'day'));

    if (filter.dateFromEvent || filter.dateToEvent || filter.dateFrom || filter.dateTo)
        data = data.sort((a, b) => (a['product'].slice(5) < b['product'].slice(5) ? -1 : 1));

    if (filter.event)
        data = data.filter(x => x.event && NormalizeString(x.event).includes(NormalizeString(filter.event)));
    if (filter.product)
        data = data.filter(x => x.product && NormalizeString(x.product).includes(NormalizeString(filter.product)));
    if (filter.client)
        data = data.filter(x => x.client && NormalizeString(x.client).includes(NormalizeString(filter.client)));

    if (filter.status !== 5)
        data = data.filter(x => x.status !== 5);

    if (filter.status !== 6)
        data = data.filter(x => x.status !== 6);


    if (Number.isInteger(filter.status))
        data = data.filter(x => x.status === filter.status);

    return (
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12} style={{
                position: 'sticky',
                top: '0',
                padding: '16px',
                zIndex: 50
            }}>
                <Paper style={{ padding: '16px' }}>
                    {props.embeddedButton &&
                        <Link to='/rentals/create'>
                            <Button color='primary'>
                                Nova locação
                        </Button>
                        </Link>
                    }
                    <FilterPanel title='Filtrar Lista'>
                    <Grid>

                        <DatePicker
                            disableOpenOnEnter
                            value={filter.dateFromEvent}
                            keyboard
                            onChange={(date) => handleFilterChange({ target: { id: 'dateFromEvent', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Data do evento De"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />

                        <DatePicker
                            disableOpenOnEnter
                            keyboard
                            value={filter.dateToEvent}
                            onChange={(date) => handleFilterChange({ target: { id: 'dateToEvent', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Data do evento Até"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />

                        <DatePicker
                            disableOpenOnEnter
                            value={filter.dateFrom}
                            keyboard
                            onChange={(date) => handleFilterChange({ target: { id: 'dateFrom', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Data da locação De"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />

                        <DatePicker
                            disableOpenOnEnter
                            keyboard
                            value={filter.dateTo}
                            onChange={(date) => handleFilterChange({ target: { id: 'dateTo', value: date } })}
                            mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            label="Data da locação Até"
                            format="DD/MM/YYYY"
                            clearable={true}
                        />

                        <TextField
                            id='event'
                            label='Evento'
                            value={filter.event}
                            onChange={handleFilterChange} />

                        <TextField
                            id='product'
                            label='Produto'
                            value={filter.product}
                            onChange={handleFilterChange} />

                        <TextField
                            id='client'
                            label='Cliente'
                            value={filter.client}
                            onChange={handleFilterChange} />
                    </Grid>

                    </FilterPanel>

                    <FormControl style={{ minWidth: 120, marginTop: 30 }} fullWidth>
                        <InputLabel htmlFor="status">Status</InputLabel>

                        <Select
                            value={filter.status}
                            onChange={handleFilterChange}
                            inputProps={{
                                id: 'status',
                                name: 'status',
                            }}
                        >
                            <MenuItem value="">
                                <em>Todos</em>
                            </MenuItem>
                            <MenuItem value={0}>Reservado</MenuItem>
                            <MenuItem value={1}>Ajuste marcado</MenuItem>
                            <MenuItem value={3}>Entrega marcada</MenuItem>
                            <MenuItem value={4}>Entregue para cliente</MenuItem>
                            {userRole === roles.Admin &&
                                <MenuItem value={5}>Devolvido</MenuItem>
                            }
                            <MenuItem value={6}>Cancelado</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={12}>

                <Table
                    {...props}
                    data={data}
                    cells={[
                        { id: 'date', label: "Data do evento", format: (x) => x && moment(x).format("LL") },
                        { id: 'event', label: "Evento" },
                        { id: 'product', label: "Produto" },
                        { id: 'client', label: "Cliente" },
                        { id: 'status', label: "Status", format: x => RentalStatus[x] },
                        { id: 'createdAt', label: "Data da locação", format: (x) => x && moment(x).format("LL") },
                    ]}

                    colorPicker={statusColorPicker}
                />
            </ItemGrid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    userRole: state.auth.userRole
})

export default connect(mapStateToProps)(AsTable(RentalsTable, {
    dateFrom: null,
    dateTo: null,
    dateFromEvent: null,
    dateToEvent: null,
    event: '',
    product: '',
    client: '',
    status: ''
}));