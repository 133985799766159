import React from "react";
import { withStyles, FormControl, InputLabel, Input, FormHelperText, Grid } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import PropTypes from "prop-types";
import cx from "classnames";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import customInputStyle from "assets/jss/styles/customInputStyle";
import { isFunction } from 'lodash';

const FormInput = ({ classes, label, formControlProps, success, errors, type, mask, optional, ...props }) => {
    if (typeof label === 'string' || label instanceof String)
        label = { text: label }

    const labelText = label && label.text;

    const labelClasses = cx({
        [" " + classes.labelRootSuccess]: success && !errors
    });

    const marginTop = cx({
        [classes.marginTop]: labelText === undefined
    });

    let customInputComponent = 'input';
    if (type === 'number') {
        customInputComponent = NumberFormatCustom;
    } else {
        customInputComponent = mask ? TextMaskCustom : 'input'
    }

    return (
        <Grid item>
            <FormControl
                fullWidth={true}
                {...formControlProps}
                className={formControlProps ? formControlProps.className + " " + classes.formControl : null}
            >
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + labelClasses}
                        htmlFor={props.id}
                        {...label}
                    >
                        <span>
                            {labelText}{optional && <i> - Opcional</i>}
                        </span>
                    </InputLabel>
                ) : null
                }


                <Input
                    {...props}
                    type={type}
                    classes={{
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: classes.underline,
                    }}
                    inputProps={{ mask }}
                    inputComponent={customInputComponent}
                />

                {
                    errors ?
                        <FormHelperText error>
                            {errors[0]}
                        </FormHelperText>
                        : null
                }

                {
                    errors ? (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ? (
                        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                    ) : null
                }
            </FormControl>
        </Grid>
    );
}

FormInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    classes: PropTypes.object.isRequired,

    onChange: PropTypes.func,
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    type: PropTypes.string,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    optional: PropTypes.bool,
};

export default withStyles(customInputStyle)(FormInput);



const TextMaskCustom = (props) => {
    const onKeyDown = e => {
        let { onChange } = props

        // Get cursor position and key code
        // let cursorPos = Number(e.target.selectionStart)
        let keyCode = Number(e.keyCode)

        // Do work only on backspace key and on cursor position 6 (for ')' and 10 for '-')
        if (keyCode === 8) {
            // Disable text mask events for this cursor positions
            e.preventDefault()

            // In my personal case I store phone nombers as just numbers 9999999999, instead of (999) 999-9999 
            e.target.value = e.target.value || ''
            e.target.value = e.target.value.replace(/\D+/g, '')

            // Simulate backspace delete
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)

            // Send event with new value to your onChange
            if (isFunction(onChange)) onChange(e)
        }
    }
    const { inputRef, ...inputProps } = props;
    return (
        <MaskedInput
            {...inputProps}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
              }}            placeholderChar={'\u2000'}
            guide={false}
            keepCharPositions={false}
            showMask
            onKeyDown={onKeyDown}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, ...inputProps } = props;
    return (
        <NumberFormat
            onValueChange={(values, e) => {
                e.target.value = values.floatValue;
                console.log(e.target.value)
                inputProps.onChange(e);
            }}
            {...inputProps}
            getInputRef={inputRef}
            allowNegative={false}
            prefix="R$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};