import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { TopLinearProgress, Header, Footer, Sidebar } from "components";

import { withStyles } from "@material-ui/core";

import appStyle from "assets/jss/styles/appStyle.jsx";

import appRoutes from "../../routes/main.jsx";
import { toggleDrawer } from 'ducks/ui';

import { roles } from "../../utils/variables";
import image from "assets/img/sidebar-3.jpg";
import logo from "assets/img/dmulogo.png";

const Main = ({ classes, authenticated, userRole, isFetching, toggleDrawer, drawerVisible, ...rest }) => {

  if (!authenticated)
    return (<Redirect to='/login' />);

  return (
    <div>
      <Sidebar
        routes={appRoutes}
        logoText="Dress Me Up"
        logo={logo}
        image={image}
        handleDrawerToggle={toggleDrawer}
        open={drawerVisible}
        color="blue"
        {...rest}
      />

      <main className={classes.mainPanel}>
        <Header
          routes={appRoutes}
          handleDrawerToggle={toggleDrawer}
          {...rest}
        />

        <div className={classes.content}>
          {isFetching && <TopLinearProgress color="primary" />}

          <Switch>
            {appRoutes.map((prop, key) => {

              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;

              if (prop.requiredRole === roles.Admin && userRole === roles.Seller) {
                return <Redirect from={prop.path} to='/' key={key} />;
              }

              return <Route path={prop.path} component={prop.component} key={key} />;
            })}
          </Switch>

          <Footer />

        </div>

      </main>

    </div>
  )
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  userRole: state.auth.userRole,
  authenticated: state.auth.isAuthenticated,
  isFetching: state.resources.isFetching,
  drawerVisible: state.ui.drawerVisible
});

const mapDispatchToProps = {
  toggleDrawer
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appStyle)(Main));
