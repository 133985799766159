import React from 'react'
import { TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';

const CustomTableHead = ({ cells, classes, tableHeaderColor, orderBy, order, sortHandler, enabledSelector }) => (
    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
        <TableRow>
            {cells.map((column, key) =>
                <TableCell
                    key={column.id}
                    numeric={column.numeric}
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    padding={column.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === column.id ? order : false}
                >
                    <Tooltip
                        title="Ordenar"
                        placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={(e) => sortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    </TableHead>
);

// CustomTableHead.propTypes = {

// }
CustomTableHead.defaultProps = {
    classes: {}
}
export default CustomTableHead
