import jwtDecode from "jwt-decode";
import { logout } from 'ducks/auth';
import get from 'lodash/get'

const tokenVerifierMiddleware = () => next => action => {
  if (get(action, 'CALL_API.endpoint') === "/account/login" || !action['CALL_API']) {
    return next(action);
  }

  const token = get(JSON.parse(localStorage.getItem("userData")), 'token');

  if (token) {
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return next(logout());
    }
  } else {
    return next(logout())
  }

  return next(action);
};


export default tokenVerifierMiddleware;