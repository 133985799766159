// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

var roles = {'Admin':1, 'Seller':2}
Object.freeze(roles);

const Resources = {
  Users: 'users', 
  Sellers: 'sellers',
  Clients: 'clients',
  Events: 'events',
  Products: 'products',
  Rentals: 'rentals',
}
Object.freeze(Resources);

export {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server, 
  roles,
  Resources
};
