import React from 'react';
import { Grid } from "@material-ui/core";
import { FormInput, InputsContainer } from 'components';
import { DatePicker } from 'material-ui-pickers';

const FormInputs = ({
    handleChange,
    handleFocusChange,
    name,
    date,
    local,
    description,
    validations }) => (
        <InputsContainer>
            <FormInput
                autoFocus
                label="Nome"
                errors={validations['name']}
                id="name"
                value={name}
                onChange={handleChange}
                onBlur={handleFocusChange}
            />

            <Grid item>

                <DatePicker
                    fullWidth={true}
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                    value={date}
                    onChange={(date) => handleChange({ target: { id: 'date', value: date } })}
                    label="Data do evento"
                    disablePast
                    invalidDateMessage="Formato inválido"
                    format="DD/MM/YYYY"
                />
            </Grid>

            <FormInput
                label="Local"
                errors={validations['local']}
                id="local"
                value={local}
                onChange={handleChange}
                onBlur={handleFocusChange}
            />

            <FormInput
                optional
                label="Descrição"
                errors={validations['description']}
                multiline
                id="description"
                value={description}
                onChange={handleChange}
                onBlur={handleFocusChange}
            />


        </InputsContainer>
    )


export default FormInputs;


