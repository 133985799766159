import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import Dropzone from 'react-dropzone';
import Gallery from 'react-grid-gallery';
import Close from "@material-ui/icons/Close";

const style = {
    dropzone: {
        border: '1px solid #a13ab6',
        borderRadius: '3px',
        padding: '0px 4px'
    }
};

function ImageGallery({ classes, images, onAdd, onRemove, allowChange = true }) {
    const imgs = images.map((i) => {
        i.index = images.indexOf(i);
        if (allowChange) {
            i.thumbnailCaption = (
                <div style={{ textAlign: 'center' }}>
                    <IconButton onClick={() => onRemove(i)}>
                        <Close />
                    </IconButton>
                </div>
            );
        }
        return i;
    });

    return (
        <React.Fragment>
            {allowChange &&
                <Dropzone className={classes.dropzone} onDrop={onAdd} >
                    <p>Arraste as fotos do produto, ou clique para selecionar.</p>
                </Dropzone>
            }
            <Gallery images={imgs} rowHeight={100} enableImageSelection={false} backdropClosesModal />
        </React.Fragment>
    );
}

export default withStyles(style)(ImageGallery);
