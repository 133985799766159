import React from "react";
import Close from "@material-ui/icons/Close";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import PropTypes from 'prop-types';

const DefaultSnackbar = ({ ...props }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={props.onClose}
            message={<span id="message-id">{props.message}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={props.onClose}
                >
                    <Close />
                </IconButton>,
            ]}
        />
    )
}

DefaultSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string
};

export default DefaultSnackbar;