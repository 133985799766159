import React from "react";
import { LinearProgress } from "@material-ui/core";

const TopLinearProgress = (props) => (
    <LinearProgress {...props} style={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 20000 
    }} />
);

export default TopLinearProgress;
