import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { fetchSingleResourceByQs } from '../../../ducks/resources';
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import {
    Table,
    FormNumericInput
} from "components";
import { withRouter } from 'react-router-dom'



const Reports = props => {
    const [selectedDate, handleDateChange] = useState(moment().toDate());
    const [response, handleResponseChange] = useState(
        {
            firstRentalDate: null,
            lastRentalDate: null,
            totalRentalValue: 0,
            totalDiscount: 0,
            totalAdditional: 0,
            monthRentals: []
        });

    useEffect(() => {
        props.getResourceByQuery({ mode: 'report', monthYear: selectedDate }).then(data => {
            console.log(data)
            if (data) {
                handleResponseChange(get(data, 'response'));
            }
        })
    }, [selectedDate, props.resourceId]);

    return (
        <Grid>
            <DatePicker
                views={["year", "month"]}
                label="Mês e ano"
                helperText="Selecione o mês e o ano do relatório"
                minDate={response.firstRentalDate}
                maxDate={response.lastRentalDate}
                value={selectedDate}
                onChange={v => handleDateChange(v && v.toDate())}
            />
            {response.totalRentalValue !== 0 && (
                <React.Fragment>

                    <FormNumericInput
                        label="Total (por valor de locação)"
                        id="totalRentalValue"
                        value={response.totalRentalValue}
                        disabled
                    />

                    <FormNumericInput
                        label="Total descontos"
                        id="totalDiscount"
                        value={response.totalDiscount}
                        disabled
                    />

                    <FormNumericInput
                        label="Total adicionais"
                        id="totalAdditional"
                        value={response.totalAdditional}
                        disabled
                    />
                </React.Fragment>
            )}

            {response.monthRentals && (
                <Table
                    exportable={true}
                    exportFormatter={(item, index) => ({
                        'Id sistema': item.id,
                        'Código de locação': item.code,
                        'Data da locação': moment(item.createdAt).format('DD/MM/YYYY'),
                        'Valor de locação': item.rentalValue,
                        'Valor desconto': item.discount,
                        'Valor adicional': item.additionalValue,
                    })}
                    data={response.monthRentals}
                    cells={[
                        { id: 'code', label: "Código de locação" },
                        { id: 'createdAt', label: "Data da locação", format: (x) => moment(x).format('DD/MM/YYYY') },
                        { id: 'rentalValue', label: "Valor de locação", format: x => `R$${x}` },
                        { id: 'discount', label: "Valor desconto", format: x => `R$${x}` },
                        { id: 'additionalValue', label: "Valor adicional", format: x => `R$${x}` },
                    ]}
                    handleItemSelected={(event, item) => props.history.push(`/rentals/${item.id}`)}
                    fixed={false}
                    exportName={`${response.sellerName}_${response.formattedPeriod}`}
                />
            )}

        </Grid>
    )
}

Reports.propTypes = {
    resource: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired
}

function mapDispatchToProps(dispatch, { resource, resourceId }) {
    return {
        getResourceByQuery: (queryObj) => dispatch(fetchSingleResourceByQs(resource, resourceId)(queryObj))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Reports));