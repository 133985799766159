import { CALL_API } from '../utils/apiMiddleware'
import * as ActionTypes from './resources'

export const CONTRACT_UPDATE = 'dmu-manager-web/resources/CONTRACT_UPDATE';

const initialState = {
    newContract: null
};

export default function reducer (state = initialState, action) {
    const { type, newContract } = action;
    
    switch (type) {
        case CONTRACT_UPDATE:
            return {
                ...state,
                newContract
            }
        default:
            return state;
    }
}

export const updateContract = (newContract) => ({
    type: CONTRACT_UPDATE,
    newContract
})

export const fetchAllRentals = (filter) => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/rentals/${filter || ""}`
    }
})

export const fetchRental = (id) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_SINGLE_REQUEST, ActionTypes.GET_SINGLE_SUCCESS, ActionTypes.GET_SINGLE_FAILURE],
            endpoint: `/rentals/${id}`
        }
    })
}

export const postRental = (rental) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/rentals/`,
            method: 'POST',
            body: rental
        }
    })
}

export const putRental = (rentalId, rental) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.PUT_REQUEST, ActionTypes.PUT_SUCCESS, ActionTypes.PUT_FAILURE],
            endpoint: `/rentals/${rentalId}`,
            method: 'PUT',
            body: rental
        }
    })
}

export const deleteRental = rentalId => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.DELETE_REQUEST, ActionTypes.DELETE_SUCCESS, ActionTypes.DELETE_FAILURE],
            endpoint: `/rentals/${rentalId}`,
            method: 'DELETE'
        }
    })
}