import get from 'lodash/get';
import * as ActionTypes from './resources';
import { CALL_API } from '../utils/apiMiddleware';
import { push } from 'connected-react-router';

export const LOGIN_USER_SUCCESS = 'dmu-manager-web/resources/LOGIN_USER_SUCCESS';
export const LOGOUT_USER = 'dmu-manager-web/resources/LOGOUT_USER';

let userData = JSON.parse(localStorage.getItem('userData'));
const initialState = userData ?
    {
        token: userData.token,
        userName: get(userData, 'user.username'),
        userRole: get(userData, 'user.role'),
        isAuthenticated: true,
        isAuthenticating: false,
        statusText: null,
    }
    : {
        token: null,
        userName: null,
        userRole: null,
        isAuthenticated: false,
        isAuthenticating: false,
        statusText: null,
    };

export default function reducer(state = initialState, action) {
    const { type, resource, response } = action;
    if (resource === 'account')
        switch (type) {
            case ActionTypes.POST_REQUEST:
                return {
                    ...state,
                    isAuthenticating: true,
                    statusText: null
                };
            case LOGIN_USER_SUCCESS:
                return {
                    ...state,
                    isAuthenticating: false,
                    isAuthenticated: true,
                    token: response.token,
                    userName: get(response, 'user.username'),
                    userRole: get(response, 'user.role')
                }
            case ActionTypes.POST_FAILURE:
                return {
                    ...state,
                    isAuthenticating: false,
                    isAuthenticated: false,
                    token: null,
                    userName: null,
                }
            case LOGOUT_USER:
                return {
                    ...state,
                    isAuthenticated: false,
                    token: null,
                    userName: null,
                }
            default:
                return state;
        }
    else
        return state;
}




export const loginUserSuccess = (response) => {
    localStorage.setItem('userData', JSON.stringify(response));
    return {
        type: LOGIN_USER_SUCCESS,
        resource: 'account',
        response,
    }
}

export function logout() {
    localStorage.clear();
    return {
        type: LOGOUT_USER,
        resource: 'account'
    }
}

export function logoutAndRedirect(history) {
    return (dispatch, state) => {
        dispatch(logout());
        dispatch(push('/login'));
    }
}

export const loginUser = (username, password) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/account/login`,
            method: 'POST',
            body: { username, password }
        }
    })
}