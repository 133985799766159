import React from 'react';
import { Grid, MenuItem, Typography, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { connect } from 'react-redux';
import { fetchAllResources } from 'ducks/resources';
import { Resources } from 'utils/variables';
import get from 'lodash/get';
import moment from 'moment';
import FormNumericInput from '../../../components/CustomInput/FormNumericInput';
import { updateContract } from 'ducks/rentals';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { InlineDatePicker } from 'material-ui-pickers';
import { fetchAvailableAdjustmentTimes, fetchAvailableDeliveryTimes } from 'ducks/agenda';

class ContractForm extends React.Component {
    state = {
        client: { name: '', cpf: '' },
        event: { name: '', date: moment() },
        product: { code: '', name: '', rentalValue: '' },
        adjustmentDate: null,
        adjustmentTime: '',
        isAdjustmentSameDay: false,
        deliveryDate: null,
        deliveryTime: '',
        discount: null,
        additionalValue: null,
        paidValue: null,
        paymentForm: 0,
        sellerId: '',
        notes: ''
    }

    componentDidMount() {
        const rentalState = get(this.props, 'location.state');
        const client = get(rentalState, 'steps[0].selected');
        const event = get(rentalState, 'steps[1].selected');
        const product = get(rentalState, 'steps[2].selected');

        this.setState({
            client,
            clientId: client.id,
            event,
            eventId: event.id,
            product,
            productId: product.id,
        }, () => this.props.updateContract(this.state))
        this.props.getSellers();
    }

    handleChange = event => {
        const { id, name, value } = event.target;

        this.setState({ [id || name]: value },
            () => this.props.updateContract(this.state));
    };

    handleCheckboxChange = (id) => {
        this.setState({ [id]: !this.state.isAdjustmentSameDay });
    };


    handleDateChange = (field, date) => {
        this.setState(
            { [field]: date },
            () => {
                if (field === 'adjustmentDate')
                    this.props.getAvailableAdjustmentTimes(date.format()).then((r) => this.setState({ adjustmentTime: '' }));

                if (field === 'deliveryDate')
                    this.props.getAvailableDeliveryTimes(date.format()).then((r) => this.setState({ deliveryTime: '' }));

                this.props.updateContract(this.state);
            }
        )
    };

    render() {
        const { sellers, availableAdjustmentTimes, availableDeliveryTimes } = this.props;
        const { client, event, product, adjustmentDate, deliveryDate, discount, paidValue, paymentForm,
            sellerId, additionalValue, adjustmentTime, deliveryTime, notes, isAdjustmentSameDay } = this.state;

        return (
            <Grid container direction='column' spacing={24}>
                <Typography variant="h5" gutterBottom>
                    Locação
                </Typography>

                <TextField
                    fullWidth={true}
                    id='client'
                    label="Cliente"
                    value={`${client.name} - ${client.cpf}`}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    fullWidth={true}
                    id='event'
                    label="Evento"
                    value={`${event.name} - ${moment(get(event, 'date')).format('LL')}`}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    fullWidth={true}
                    id='product'
                    label="Produto"
                    value={`${product.code} - ${product.name}`}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    id="sellerId"
                    name="sellerId"
                    select
                    label="Vendedora"
                    value={sellerId}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    disabled={!Array.isArray(sellers) || !sellers.length}
                >
                    {sellers.map(s => (
                        <MenuItem key={s.id} value={s.id}>{s.fullName}</MenuItem>
                    ))}
                </TextField>


                <Typography variant="h5" gutterBottom>
                    Ajuste
                </Typography>

                <Grid container direction='row' justify="space-around">

                    <FormControlLabel
                        control={
                            <Checkbox checked={isAdjustmentSameDay}
                                onChange={(evt) => this.handleCheckboxChange("isAdjustmentSameDay")}
                                value={isAdjustmentSameDay}
                            />
                        }
                        label="Ajuste para o mesmo dia"

                    />

                    <InlineDatePicker
                        keyboard
                        clearable
                        variant="outlined"
                        margin="normal"
                        label="Data Ajuste"
                        onChange={(date) => this.handleDateChange('adjustmentDate', date)}
                        value={adjustmentDate}
                        format="DD/MM/YYYY"
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    />

                    <TextField
                        id="adjustmentTime"
                        name="adjustmentTime"
                        select
                        label="Hora Ajuste"
                        value={adjustmentTime}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        style={{ minWidth: '200px' }}
                        disabled={!Array.isArray(availableAdjustmentTimes) || !availableAdjustmentTimes.length}
                        helperText={!adjustmentDate && "Selecione a data do ajuste para ver os horários disponíveis"}
                    >
                        {availableAdjustmentTimes.map(option => (
                            <MenuItem key={option.time}
                                value={option.time}
                                disabled={!option.available}
                                style={{ backgroundColor: !option.available && '#ffb2b2' }}>
                                {option.time}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {adjustmentDate && adjustmentTime ?
                    (
                        <React.Fragment>

                            <Typography variant="h5" gutterBottom>
                                Entrega
                        </Typography>

                            <Grid container direction='row' justify="space-around">

                                <InlineDatePicker
                                    keyboard
                                    clearable
                                    variant="outlined"
                                    margin="normal"
                                    label="Data Entrega"
                                    onChange={(date) => this.handleDateChange('deliveryDate', date)}
                                    value={deliveryDate}
                                    format="DD/MM/YYYY"
                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                />

                                <TextField
                                    id="deliveryTime"
                                    name="deliveryTime"
                                    select
                                    label="Hora Entrega"
                                    value={deliveryTime}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    style={{ minWidth: '200px' }}
                                    disabled={!Array.isArray(availableDeliveryTimes) || !availableDeliveryTimes.length}
                                    helperText={!deliveryDate && "Selecione a data da entrega para ver os horários disponíveis"}
                                >
                                    {availableDeliveryTimes.map(option => (
                                        <MenuItem key={option.time}
                                            value={option.time}
                                            disabled={!option.available}
                                            style={{ backgroundColor: !option.available && '#ffb2b2' }}>
                                            {option.time}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </React.Fragment>
                    )
                    : null
                }

                <Typography variant="h5" gutterBottom>
                    Informações de pagamento
                </Typography>

                <FormNumericInput
                    label="Valor De Locação"
                    id="rentalValue"
                    value={product.rentalValue}
                    InputProps={{ readOnly: true }}
                />

                <Grid container direction='row' justify="space-around">
                    <FormNumericInput
                        fullWidth={false}
                        label="Valor Desconto"
                        id="discount"
                        onChange={this.handleChange}
                        value={discount}
                    />

                    <FormNumericInput
                        fullWidth={false}
                        label="Valor Adicional"
                        id="additionalValue"
                        onChange={this.handleChange}
                        value={additionalValue}
                    />
                </Grid>

                <FormNumericInput
                    InputProps={{
                        style: { backgroundColor: deepPurple[50] },
                    }}
                    label="Valor Pago"
                    id="paidValue"
                    onChange={this.handleChange}
                    value={paidValue}
                />

                <FormNumericInput
                    label="Valor Total"
                    id="totalValue"
                    value={Number(product.rentalValue - discount + additionalValue - paidValue)}
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    id="paymentForm"
                    name="paymentForm"
                    select
                    label="Forma de Pagamento"
                    value={paymentForm}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                >
                    <MenuItem value={0}>Dinheiro</MenuItem>
                    <MenuItem value={1}>Cartão de débito</MenuItem>
                    <MenuItem value={2}>Cartão de crédito</MenuItem>
                    <MenuItem value={3}>Transferência bancária</MenuItem>
                </TextField>

                <TextField
                    fullWidth={true}
                    id='notes'
                    label="Observações"
                    value={notes}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                />
            </Grid>
        )
    }
};


const mapStateToProps = (state) => ({
    sellers: state.resources.sellers,
    availableAdjustmentTimes: state.resources.availableAdjustmentTimes,
    availableDeliveryTimes: state.resources.availableDeliveryTimes,
});

function mapDispatchToProps(dispatch) {
    return {
        getSellers: () => dispatch(fetchAllResources(Resources.Sellers)),
        updateContract: (state) => dispatch(updateContract(state)),
        getAvailableAdjustmentTimes: (date) => dispatch(fetchAvailableAdjustmentTimes(date)),
        getAvailableDeliveryTimes: (date) => dispatch(fetchAvailableDeliveryTimes(date)),
    }
}
ContractForm.defaultProps = {
    sellers: [],
    availableAdjustmentTimes: [],
    availableDeliveryTimes: []
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractForm);