import React from "react";
import { WithAll, Button } from "components";
import ClientsTable from '../Clients/ClientsTable';
import get from 'lodash/get';
import { Tabs, Tab } from "@material-ui/core";
import FormInputs from "../Clients/FormInputs";
import validator from "utils/validator";
import constraints from "../Clients/constraints";
import { connect } from 'react-redux';
import { postClient, fetchAllClients } from 'ducks/clients';

class ClientSelector extends React.Component {

    state = {
        tabPos: 0,
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        telNumber: '',
        rg: '',
        birthDate: null,

        addressCode: '',
        addressLine: '',
        addressNumber: '',
        city: '',
        district: '',
        state: '',
        facebookUri: '',
        instagramUri: '',
        twitterUri: '',

        validations: {}
    }

    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleNumericChange = (event, value) => {
        const { id } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleCEPFocusChange = event => {
        const { id, value } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }),
            () => {
                if (this.state.validations[id]) {
                    return;
                } else {
                    fetch(`https://viacep.com.br/ws/${value}/json/`)
                        .then(response => response.json())
                        .then(json => this.setState(({
                            addressLine: !json.erro ? `${json.logradouro} - ${json.bairro} - ${json.localidade} - ${json.uf}` : ''
                        })))
                }
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.tabPos === 0)
            this.props.handleNext();
        else {
            this.setState(
                (prevState) => ({ validations: validator(prevState, constraints) }),
                () => {
                    const client = { ...this.state };


                    this.props.postClient(client).then((data) => {
                        if (get(data, 'response.success')) {
                            this.props.onSelected(null, get(data, 'response.data'))
                            this.props.handleNext();
                        }

                    })

                });
        }
    };



    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    render() {
        const { data, refresh, onSelected, selected, steps, activeStep, handleBack } = this.props;
        const {
            tabPos,
            validations,
            name,
            email,
            cpf,
            cellNumber,
            telNumber,
            rg,
            birthDate,
            addressCode,
            addressLine,
            facebookUri,
            instagramUri,
            twitterUri
        } = this.state;

        return (
            <div>
                <Tabs value={tabPos} onChange={this.handleTabChange}>
                    <Tab label="Lista" />
                    <Tab label="Novo" />
                </Tabs>

                {tabPos === 0 &&
                    <div style={{ textAlign: 'center' }}>
                        {selected && <h3>{`${get(selected, 'name')} - ${get(selected, 'cpf')}`}</h3>}
                        <ClientsTable onRefresh={refresh} fixed={data.length > 10} data={data} handleItemSelected={onSelected} />
                    </div>
                }

                {tabPos === 1 &&
                    <FormInputs
                        handleChange={this.handleChange}
                        handleFocusChange={this.handleFocusChange}
                        handleCEPFocusChange={this.handleCEPFocusChange}
                        validations={validations}
                        name={name}
                        email={email}
                        cpf={cpf}
                        cellNumber={cellNumber}
                        telNumber={telNumber}
                        rg={rg}
                        birthDate={birthDate}
                        addressCode={addressCode}
                        addressLine={addressLine}
                        facebookUri={facebookUri}
                        instagramUri={instagramUri}
                        twitterUri={twitterUri}
                    />}

                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                    </Button>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postClient: client => dispatch(postClient(client)),
    }
}



export default WithAll('clients', fetchAllClients)(connect(null, mapDispatchToProps)(ClientSelector));