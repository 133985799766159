import React from "react";
import { connect } from 'react-redux';

const WithAll = (resource, action) => WrappedComponent => {
    class Connected extends React.Component {
        componentDidMount() {
            this.props.getData(this.props.actionParams && this.props.actionParams);
        }

        refresh = () => {
            this.props.getData(this.props.actionParams && this.props.actionParams);
        }

        render() {
            return (<WrappedComponent refresh={this.refresh} data={this.props.data} {...this.props} />)
        }
    }

    const mapStateToProps = (state) => ({
        data: state.resources[resource] || []
    });

    const mapDispatchToProps = (dispatch) => ({
        getData: (params) => dispatch(action(params))
    });

    return connect(mapStateToProps, mapDispatchToProps)(Connected);
}

export default WithAll;
