import React from "react";
import { TextField, Grid, Paper } from "@material-ui/core";
import { Table, ItemGrid, FilterPanel, Button } from "../../../components";
import AsTable from "../../../components/HOC/AsTable";
import { NormalizeString } from "../../../utils";
import { Link } from 'react-router-dom';

const ClientsTable = props => {
    const { handleFilterChange, filter, embeddedButton } = props;
    let { data } = props;

    if (filter.name)
        data = data.filter(x => x.name && NormalizeString(x.name).includes(NormalizeString(filter.name)));

    if (filter.cpf)
        data = data.filter(x => x.cpf && NormalizeString(x.cpf).includes(NormalizeString(filter.cpf)));

    if (filter.email)
        data = data.filter(x => x.email && NormalizeString(x.email).includes(NormalizeString(filter.email)));

    if (filter.cellNumber)
        data = data.filter(x => x.cellNumber && NormalizeString(x.cellNumber).includes(NormalizeString(filter.cellNumber)));

    if (filter.telNumber)
        data = data.filter(x => x.telNumber && NormalizeString(x.telNumber).includes(NormalizeString(filter.telNumber)));

    return (
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12} style={{
                position: 'sticky',
                top: '0',
                padding: '16px',
                zIndex: 50
            }}>
                <Paper style={{ padding: '16px' }}>
                    {embeddedButton &&
                        <Link to='/clients/create' >
                            <Button color='primary'>
                                Novo cliente
                        </Button>
                        </Link>
                    }

                    <FilterPanel title='Filtrar Lista'>

                        <TextField
                            id='name'
                            label='Nome'
                            value={filter.name}
                            onChange={handleFilterChange} />

                        <TextField
                            id='cpf'
                            label='CPF'
                            value={filter.cpf}
                            onChange={handleFilterChange} />

                        <TextField
                            id='email'
                            label='Email'
                            value={filter.email}
                            onChange={handleFilterChange} />

                        <TextField
                            id='cellNumber'
                            label='Celular'
                            value={filter.cellNumber}
                            onChange={handleFilterChange} />

                        <TextField
                            id='telNumber'
                            label='Telefone'
                            value={filter.telNumber}
                            onChange={handleFilterChange} />
                    </FilterPanel>
                </Paper>

            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={12}>

                <Table
                    {...props}
                    data={data}
                    cells={[{ id: 'name', label: "Nome" },
                    { id: 'cpf', label: "CPF" },
                    { id: 'email', label: "Email" },
                    { id: 'cellNumber', label: "Celular" },
                    { id: 'telNumber', label: "Telefone" }]}
                />
            </ItemGrid>
        </Grid>
    )
}


export default AsTable(ClientsTable, {
    name: '',
    cpf: '',
    email: '',
    cellNumber: '',
    telNumber: ''
});