// ##############################
// // // App styles
// #############################

import { drawerWidth, transition, container } from "assets/jss/styles.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    // overflow: "auto",
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: 'touch',
    ...transition,
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
    },
    padding: "30px 0px",
    minHeight: "calc(100% - 123px)",
  },
  container
});

export default appStyle;
