import React from 'react';
import { Grid } from "@material-ui/core";

const InputsContainer = ({ children }) =>
    (
        <Grid container item direction='column' md={6} spacing={24}>
            {children}
        </Grid>
    )


export default InputsContainer;