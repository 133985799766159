import React from "react";
import { Grid, TextField, Paper, Drawer, IconButton } from "@material-ui/core";
import { RegularCard, Table, ItemGrid, Button, FilterPanel } from "components";
import { Link, Route } from 'react-router-dom';
import { fetchAllResources } from 'ducks/resources';
import { withRouter } from "react-router-dom";
import AsTable from "../../../components/HOC/AsTable";
import { Resources } from 'utils/variables';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Details from "./Details";

class List extends React.Component {

    componentDidMount() {
        this.props.getData(this.props.actionParams && this.props.actionParams);
    }

    handleItemSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/users/${item.id}`)
    }

    render() {
        let { data, filter, handleFilterChange } = this.props;

        if (filter.username) {
            data = data.filter(x => x.username && x.username.toLowerCase().trim().includes(filter.username.toLowerCase().trim()));
        }

        return (
            <React.Fragment>

                <RegularCard cardTitle="Usuários">
                    <Grid container>
                        <ItemGrid xs={12} sm={12} md={12} style={{
                            position: 'sticky',
                            top: '0',
                            padding: '16px',
                            zIndex: 50
                        }}>
                            <Paper style={{ padding: '16px' }}>
                                <Link to='/users/create'>
                                    <Button color='primary'>
                                        Novo Usuário
                            </Button>
                                </Link>
                                <FilterPanel title='Filtrar Lista'>
                                    <TextField
                                        id='username'
                                        label='Nome de usuário'
                                        value={filter.username}
                                        onChange={handleFilterChange} />

                                </FilterPanel>
                            </Paper>
                        </ItemGrid>

                        <ItemGrid xs={12} sm={12} md={12}>
                            <Table
                                {...this.props}
                                onRefresh={() => this.props.getData(this.props.actionParams && this.props.actionParams)}
                                handleItemSelected={this.handleItemSelected}
                                data={data}
                                cells={[{ id: 'username', label: "Nome de usuário" }]} />
                        </ItemGrid>
                    </Grid>
                </RegularCard>
                <Route path="/users/:id">
                    {({ match, history }) => {
                        const isMatch =
                            match &&
                                match.params ? true : false;
                        return (
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                            >
                                {isMatch ? (
                                    <Grid container style={{ maxWidth: '500px' }}>
                                        <IconButton onClick={() => history.replace("/users")} style={{ marginTop: '24px', marginBottom: '24px' }}>
                                            <CloseIcon />
                                        </IconButton>
                                        <Details />
                                    </Grid>
                                ) : null}
                            </Drawer>
                        );
                    }}
                </Route>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.resources['users'] || []
});

const mapDispatchToProps = (dispatch) => ({
    getData: () => dispatch(fetchAllResources(Resources.Users))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AsTable(List, { username: '' })));
