import { CALL_API } from '../utils/apiMiddleware'

import * as ActionTypes from './resources'

export const fetchAllClients = () => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/clients/`
    }
})

export const fetchClient = (id) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_SINGLE_REQUEST, ActionTypes.GET_SINGLE_SUCCESS, ActionTypes.GET_SINGLE_FAILURE],
            endpoint: `/clients/${id}`
        }
    })
}

export const postClient = (client) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/clients/`,
            method: 'POST',
            body: client
        }
    })
}

export const putClient = (clientId, client) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.PUT_REQUEST, ActionTypes.PUT_SUCCESS, ActionTypes.PUT_FAILURE],
            endpoint: `/clients/${clientId}`,
            method: 'PUT',
            body: client
        }
    })
}

export const deleteClient = clientId => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.DELETE_REQUEST, ActionTypes.DELETE_SUCCESS, ActionTypes.DELETE_FAILURE],
            endpoint: `/clients/${clientId}`,
            method: 'DELETE'
        }
    })
}