import React from "react";
import { Grid, Tabs, Tab, Paper, Drawer, IconButton } from "@material-ui/core";
import { RegularCard, ItemGrid, WithAll } from "components";
import { Route } from 'react-router-dom';
import { fetchAllProducts } from 'ducks/products'
import { withRouter } from "react-router-dom";
import ProductsTable from "./ProductsTable";
import AvailableList from "./AvailableList";
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Details from "./Details";
import { has, get, isEqual } from 'lodash';

class List extends React.Component {
    state = {
        tabPos: 0
    }

    componentDidMount() {
        if (get(this.props, 'match.path').includes('available'))
            this.setState({ tabPos: 1 })
    }

    handleItemSelected = (event, item) => {
        event.preventDefault()
        const { history } = this.props;
        history.push(`/products/${item.id}`)
    }

    handleTabChange = (event, value) => {
        this.setState({ tabPos: value });
    };

    render() {
        return (
            <React.Fragment>

                <RegularCard cardTitle="Produtos" >
                    <Grid container direction='column' alignItems="stretch">

                        <ItemGrid style={{ marginTop: 24, marginBottom: 24 }}>
                            <Paper>
                                <Tabs
                                    value={this.state.tabPos}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabChange}>

                                    <Tab label="Lista" />
                                    <Tab label="Disponibilidade" />
                                </Tabs>
                            </Paper>
                        </ItemGrid>

                        {this.state.tabPos === 0 ?
                            <ProductsTable
                                onRefresh={this.props.refresh}
                                data={this.props.data}
                                handleItemSelected={this.handleItemSelected}
                                fixed={false}
                                showStatus
                                filter={{ showAvailable: true }}
                                userRole={this.props.userRole}
                                embeddedButton={true}
                            />
                            :
                            <Grid item container direction="column" alignItems="center" md={12}>
                                <AvailableList onSelected={this.handleItemSelected} />
                            </Grid>
                        }
                    </Grid>
                </RegularCard>

                <Route path="/products/:id" exact>
                    {({ match, history }) => {

                        const isMatch = has(match, 'params.id') && !isEqual(get(match, 'params.id'), 'available');

                        return (
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                            >
                                {isMatch ? (
                                    <Grid container style={{ maxWidth: '500px' }}>
                                        <IconButton onClick={() => history.replace("/products")} style={{ marginTop: '24px', marginBottom: '24px' }}>
                                            <CloseIcon />
                                        </IconButton>
                                        <Details />
                                    </Grid>
                                ) : null}
                            </Drawer>
                        );
                    }}
                </Route>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userRole: state.auth.userRole
});

export default withRouter(WithAll('products', fetchAllProducts)(connect(mapStateToProps, null)(List)));

