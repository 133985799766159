import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import {
    RegularCard,
    Button
} from "components";
import validator from "utils/validator";
import { FormInput, InputsContainer } from 'components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import get from 'lodash/get';
import { putResource, postResource, fetchSingleResource } from '../../../ducks/resources';
import { Resources } from 'utils/variables';

class Form extends Component {
    state = {
        fullName: '',
        cpf: '',
        validations: {}
    }

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        id && this.props.getSeller(id).then(x => {
            const seller = x.response;
            this.setState({
                fullName: seller.fullName,
                cpf: seller.cpf,
            })
        });
    }


    handleChange = event => {
        const { id, value } = event.target
        this.setState({
            [id]: value,
        });
    };

    handleFocusChange = event => {
        const { id } = event.target

        this.setState((prevState) => ({
            validations: {
                ...prevState.validations,
                [id]: validator(prevState, constraints)[id]
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (prevState) => ({ validations: validator(prevState, constraints) }),
            () => {
                if (this.state.validations !== true) {
                    return;
                } else {
                    const { id } = this.props.match.params;
                    const seller = { ...this.state };

                    if (id) {
                        this.props.putSeller(id, seller).then((data) => data.response && this.props.history.push(`/sellers/${id}`))
                    } else {
                        this.props.postSeller(seller).then((data) => data.response && this.props.history.push(`/sellers/${data.response.data.id || ''}`))
                    }
                }
            });
    };

    render() {
        const { id } = this.props.match.params;
        const title = id ? `Editar Vendedora` : "Nova Vendedora"
        const { validations, fullName, cpf } = this.state;
        const subtitle = id ? `Você está editando os dados da vendedora ${this.props.selectedSeller.fullName}.` : "Insira os dados da nova vendedora."

        return (
            <RegularCard
                cardTitle={title}
                cardSubtitle={subtitle}

            >
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <InputsContainer>
                            <FormInput
                                autoFocus
                                label="Nome completo"
                                errors={validations['fullName']}
                                id="fullName"
                                value={fullName}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                            />

                            <FormInput
                                label="CPF"
                                errors={validations['cpf']}
                                id="cpf"
                                value={cpf}
                                onChange={this.handleChange}
                                onBlur={this.handleFocusChange}
                                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}

                            />
                        </InputsContainer>

                        <Button color="primary" type='submit' style={{ marginTop: 48 }}>Salvar Vendedora</Button>
                        <Link to="/sellers" style={{ alignSelf: "flex-start" }}>Voltar Para Lista</Link>
                    </Grid>

                </form>
            </RegularCard>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedSeller: state.resources.selectedResource
});

function mapDispatchToProps(dispatch) {
    return {
        putSeller: (sellerId, seller) => dispatch(putResource(Resources.Sellers, sellerId, seller)),
        postSeller: seller => dispatch(postResource(Resources.Sellers, seller)),
        getSeller: (id) => dispatch(fetchSingleResource(Resources.Sellers, id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));

const constraints = {
    fullName: {
        presence: { message: "Por favor, informe o nome completo.", allowEmpty: false },
        length: {
            minimum: 3,
            tooShort: "O nome precisa ter pelo menos 3 caracteres"
        }
    },
    cpf: {
        presence: { message: "Por favor, informe o CPF.", allowEmpty: false },
        format: {
            pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
            message: "Informe um CPF válido"
        }
    }
};