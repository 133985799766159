export function checkHttpStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

export function createReducer(initialState, reducerMap) {
    return (state = initialState, action) => {
        const reducer = reducerMap[action.type];

        return reducer
            ? reducer(state, action.payload)
            : state;
    };
}

export function parseJSON(response) {
    return response.json()
}

export function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}

export function updateObjectInArray(array, obj) {
    if (!Array.isArray(array))
        return obj;

    return array.map((item, index) => {
        if (item.id !== obj.id) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        console.log(obj)
        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...obj
        };
    });
}

export function dateToInput(date) {
    date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var today = year + "-" + month + "-" + day;
    return today;
}

export const getFromQueryString = (q) => ((a) => {
    if (a === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=');
        if (p.length !== 2) continue;
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
})(q.split("&"));

export const RentalStatus = {
    0: 'Reservado',
    1: 'Ajuste marcado',
    2: 'Ajuste realizado',
    3: 'Entrega marcada',
    4: 'Entregue para cliente',
    5: 'Devolvido',
    6: 'Cancelado',
};

export const PaymentForm = {
    0: 'Dinheiro',
    1: 'Cartão de débito',
    2: 'Cartão de crédito',
    3: 'Transferência',
};


export const NormalizeString = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^A-Za-z0-9\s]/g, "").toLowerCase().trim()
