import React from "react";
import { Grid, Drawer } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { RegularCard, WithAll } from "components";
import { fetchAllClients } from 'ducks/clients';
import { withRouter, Route } from "react-router-dom";
import ClientsTable from "./ClientsTable";
import Details from "./Details";

const List = (props) => {
    const handleItemSelected = (event, item) => {
        event.preventDefault()
        const { history } = props;
        history.push(`/clients/${item.id}`)
    }

    return (
        <React.Fragment>

            <RegularCard cardTitle="Clientes">
                <ClientsTable onRefresh={props.refresh}
                    data={props.data}
                    handleItemSelected={handleItemSelected}
                    embeddedButton={true} />
            </RegularCard>
            <Route path="/clients/:id">
                {({ match, history }) => {
                    const isMatch =
                        match &&
                            match.params ? true : false;
                    return (
                        <Drawer
                            variant="persistent"
                            open={isMatch}
                            anchor="right"
                        >
                            {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                            {isMatch ? (
                                <Grid container style={{ maxWidth: '500px' }}>
                                    <IconButton onClick={() => history.replace("/clients")} style={{ marginTop: '24px', marginBottom: '24px' }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Details />
                                </Grid>
                            ) : null}
                        </Drawer>
                    );
                }}
            </Route>
        </React.Fragment>

    );
}


export default withRouter(WithAll('clients', fetchAllClients)(List));
