import { get } from 'lodash';
import {SHOW_MESSAGE} from 'ducks/ui';
import {LOGOUT_USER} from 'ducks/auth';

const API_ROOT = `${process.env.REACT_APP_API_ENDPOINT}/v1`

const callApi = (endpoint, method = 'GET', body = null) => {
    const token = get(JSON.parse(localStorage.getItem('userData')), 'token');
    const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint
    const options = {
        method,
        body: body && JSON.stringify(body),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
    }

    return fetch(fullUrl, options)
        .then(response => {
            if (response.status === 401) {
                return Promise.reject({ statusCode: 401, message: "Sessão inválida ou expirada, faça o login novamente." })
            }
            return response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json)
                }
                return json;
            })
        })
        .catch(e => {
            console.log(e);
            if (get(e, 'data[0]')) {
                const message = get(e, 'data[0].message');
                const property = get(e, 'data[0].property');
                if (message && property)
                    throw new Error(`${property}: ${message}`)
            }

            if (!e.message || e.message === "Failed to fetch")
                throw new Error('Não foi possível se comunicar com o servidor.')
            else
                throw new Error(e.message)

        })
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'CALL_API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callAPI = action[CALL_API]
    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    const { types, endpoint, method, body, resourceName } = callAPI

    if (typeof endpoint !== 'string')
        throw new Error('Specify a string endpoint URL.')

    if (!Array.isArray(types) || types.length !== 3)
        throw new Error('Expected an array of three action types.')

    if (!types.every(type => typeof type === 'string'))
        throw new Error('Expected action types to be strings.')

    const actionWith = data => {
        const finalAction = Object.assign({}, action, data)
        delete finalAction[CALL_API]
        return finalAction
    }

    const resource = resourceName || endpoint.substring(1, endpoint.lastIndexOf('/'))

    const [requestType, successType, failureType] = types
    next(actionWith({ resource, type: requestType }))
    return callApi(endpoint, method, body).then(
        response => {
            response.message && next({ type: SHOW_MESSAGE, payload: { message: response.message } })
            return next(actionWith({
                resource,
                response,
                type: successType,
            }));
        },
        error => {
            next(actionWith({
                type: error && error.statusCode === 401 ? LOGOUT_USER : failureType,
                resource
            }));
            next({ type: SHOW_MESSAGE, payload: { message: error.message || 'Ocorreu um erro.' } })
        }
    )
}