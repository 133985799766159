import React from 'react';
import { Grid } from "@material-ui/core";
import { FormInput, InputsContainer } from 'components';
import { DatePicker } from 'material-ui-pickers';

const FormInputs = ({
    handleChange,
    handleFocusChange,
    handleCEPFocusChange,
    name,
    email,
    cpf,
    cellNumber,
    telNumber,
    rg,
    birthDate,
    addressCode,
    addressLine,
    facebookUri,
    instagramUri,
    twitterUri }) => {

    return (

        <InputsContainer>
                <FormInput
                    label="Nome"
                    // errors={validations['name']}
                    id="name"
                    value={name}
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleFocusChange} />

                <FormInput
                    label="Email"
                    // errors={validations['email']}
                    id="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />

                <FormInput
                    label="CPF"
                    // errors={validations['cpf']}
                    id="cpf"
                    value={cpf}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                />

                <FormInput
                    optional
                    label="RG"
                    // errors={validations['rg']}
                    id="rg"
                    value={rg}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />

                <FormInput
                    label="CEP"
                    // errors={validations['addressCode']}
                    id="addressCode"
                    value={addressCode}
                    onChange={handleChange}
                    onBlur={handleCEPFocusChange}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                />

                <FormInput
                    label="Endereço"
                    // errors={validations['addressLine']}
                    id="addressLine"
                    value={addressLine}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />

                <FormInput
                    label="Celular"
                    // errors={validations['cellNumber']}
                    id="cellNumber"
                    value={cellNumber}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                    mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />

                <FormInput
                    optional
                    label="Telefone"
                    // errors={validations['telNumber']}
                    id="telNumber"
                    value={telNumber}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                    mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />

                <Grid item>
                    <DatePicker
                        keyboard
                        openToYearSelection
                        fullWidth={true}
                        mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                        value={birthDate}
                        onChange={(date) => handleChange({ target: { id: 'birthDate', value: date } })}
                        label="Data de nascimento"
                        disableFuture
                        invalidDateMessage="Formato inválido"
                        format="DD/MM/YYYY"
                    />
                </Grid>

                <FormInput
                    optional
                    label="Facebook"
                    // errors={validations['facebookUri']}
                    id="facebookUri"
                    value={facebookUri}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />

                <FormInput
                    optional
                    label="Instagram"
                    // errors={validations['instagramUri']}
                    id="instagramUri"
                    value={instagramUri}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />

                <FormInput
                    optional
                    label="Twitter"
                    // errors={validations['twitterUri']}
                    id="twitterUri"
                    value={twitterUri}
                    onChange={handleChange}
                    onBlur={handleFocusChange}
                />
        </InputsContainer>

    )
}

export default FormInputs;