import React from "react";
import { withStyles, Card, CardContent, Grid, Typography, CardActionArea } from "@material-ui/core";
import { withRouter } from 'react-router-dom'

const cardStyles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
};
const DashboardCard = withStyles(cardStyles)(({ classes, children, handleClick }) => (
  <Card className={classes.card}>
    <CardActionArea onClick={handleClick}>
      <CardContent>
        {children}
      </CardContent>
    </CardActionArea>
  </Card>
));

class Dashboard extends React.Component {
  state = {
    value: 0
  };
  handleChange = (value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    return (
      <Grid container>
        <Grid item>
          <DashboardCard handleClick={() => this.props.history.push(`/products/available`)}>
            <Typography variant="h5"
              component="h2">
              Checar Disponibilidade
          </Typography>
          </DashboardCard>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Dashboard);
