import React, { Component } from 'react'
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Main from "views/Main/Main";
import Login from "views/Login/Login";
// import Login from "./Login/LoginV2";
import { loginUserSuccess, logout } from 'ducks/auth';
import { showMessage, dismissMessage } from 'ducks/ui';
import { DefaultSnackbar, PrivateRoute } from "components";


class App extends Component {
    static propTypes = {
        loginUserSuccess: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        showMessage: PropTypes.func.isRequired,
        dismissMessage: PropTypes.func.isRequired,
    }

    state = {
        snackOpen: false
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.snackOpen && this.props.message && !this.props.messageShown)
            this.setState({ snackOpen: true })
    }


    handleSnackClose = () => {
        this.setState({ snackOpen: false }, () => this.props.dismissMessage());
    };

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/login" exact component={Login} />
                    <PrivateRoute path="/" component={Main} />
                </Switch>
                <DefaultSnackbar open={this.state.snackOpen} onClose={this.handleSnackClose} message={this.props.message} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    message: state.ui.message,
    messageShown: state.ui.messageShown
});

const mapDispatchToProps = {
    loginUserSuccess,
    logout,
    showMessage,
    dismissMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
