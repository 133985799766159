import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import {
  withStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { connect } from 'react-redux';
import ExitToApp from "@material-ui/icons/ExitToApp";

import sidebarStyle from "assets/jss/styles/sidebarStyle.jsx";
import { roles } from "../../utils/variables";
import { logoutAndRedirect } from "ducks/auth"

class Sidebar extends React.Component {

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  handleExitClick = () => {
    this.props.logoutAndRedirect();
  };

  render() {
    const { classes, color, logo, image, routes, userRole, open, handleDrawerToggle } = this.props;

    const brand = (
      <div className={classes.logo}>
        <img src={logo} alt="logo" className={classes.img} />
      </div>
    );

    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {

          if (prop.redirect || prop.nested) return null;

          if (prop.requiredRole === roles.Admin && userRole === roles.Seller) {
            return null;
          }

          const listItemClasses = cx({
            [" " + classes[color]]: this.activeRoute(prop.path)
          });
          const whiteFontClasses = cx({
            [" " + classes.whiteFont]: this.activeRoute(prop.path)
          });

          return (
            <NavLink
              to={prop.path}
              className={classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}

        <ListItem button onClick={this.handleExitClick} className={classes.itemLink}>
          <ListItemIcon className={classes.itemIcon}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText
            primary='Sair'
            className={classes.itemText}
            disableTypography={true}
          />
        </ListItem>
      </List>
    );


    return (
      <React.Fragment>

        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <SidebarContent brand={brand} classes={classes} links={links} image={image} />
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <Drawer
            anchor="left"
            variant="permanent"
            open={open}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <SidebarContent brand={brand} classes={classes} links={links} image={image} />
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  };
};

const SidebarContent = ({ brand, classes, links, image }) =>
  <React.Fragment>
    <div className={classes.sidebarWrapper}>
      {brand}
      {links}
    </div>
    {image !== undefined ? (
      <div
        className={classes.background}
        style={{ backgroundImage: "url(" + image + ")" }}
      />
    ) : null}
  </React.Fragment>

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  userRole: PropTypes.number.isRequired,
  routes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAndRedirect: () => dispatch(logoutAndRedirect())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(Sidebar));