import React, { Component } from "react";
import {
  DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button,
} from "@material-ui/core";
import { Link, Route, withRouter } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { WithAll } from "components";
import { fetchAllDates } from 'ducks/agenda';
import get from 'lodash/get'
import Details from "../Rentals/Details";
import { Grid, IconButton, Drawer } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';

const localizer = momentLocalizer(moment);

const MyAgendaEvent = (props) => {
  const { title, event } = props;
  const { id, isAdjustmentSameDay } = event;

  return (<Link style={{
    color: 'inherit',
    textDecoration: 'none',
  }} to={`/agenda/rental/${id}`}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
      {isAdjustmentSameDay && <RestoreIcon />}
      {title}
    </div>
  </Link>);
}


class Agenda extends Component {
  state = {
    open: false,
    selectedEvent: {}
  }

  // handleClickOpen = (selectedEvent) => {
  //   this.setState({ open: true, selectedEvent });
  // };

  handleItemSelected = (event) => {
    const { history } = this.props;
    history.push(`/agenda/rental/${event.id}`)
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const data = get(this.props, 'data', []).map(x => ({ ...x, start: moment(x.start).toDate(), end: moment(x.end).toDate() }))

    return (
      <React.Fragment>

        <div style={{ backgroundColor: 'white', height: '100vh' }}>
          <Calendar
            popup
            localizer={localizer}
            messages={{
              previous: "Anterior",
              next: "Próximo",
              today: 'Hoje',
              month: 'Mês',
              week: "Semana",
              day: "Dia",
              date: "Data",
              event: "Evento",
              agenda: "Agenda",
              time: "Horário",
              yesterday: "Ontem",
              tomorrow: "Amanhã",
              work_week: "Dia útil",
            }}
            views={['month', 'week', 'day', 'agenda']}
            formats={{ agendaDateFormat: "LL" }}
            events={data}
            defaultView="agenda"
            defaultDate={moment().toDate()}
            onSelectEvent={event => this.handleItemSelected(event)}
            eventPropGetter={(event) => ({ style: { color: 'black', backgroundColor: event.title.includes('Ajuste') ? '#f4ffe4' : '#ffe4d5' } })}
            components={{
              agenda: {
                event: MyAgendaEvent
              }
            }}
          />

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Visitar a página da locação?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.selectedEvent.title}
                <br />
                {this.state.selectedEvent.notes && `Observações: ${this.state.selectedEvent.notes}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Voltar
            </Button>

              <Link to={`/rentals/${this.state.selectedEvent.id}`}>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                  Ver locação
            </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </div>

        <Route path="/agenda/rental/:id">
          {({ match, history }) => {
            const isMatch = match && match.params ? true : false;
            return isMatch && (
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
              >
                <Grid container style={{ maxWidth: '500px' }}>
                  <IconButton onClick={() => history.replace("/agenda")} style={{ marginTop: '24px', marginBottom: '24px' }}>
                    <CloseIcon />
                  </IconButton>
                  <Details />
                </Grid>
              </Drawer>
            );
          }}
        </Route>
      </React.Fragment>

    );


  }
}

export default withRouter(WithAll('agenda', fetchAllDates)(Agenda));
