import DashboardPage from "views/Main/Dashboard/Dashboard.jsx";
import Products from "views/Main/Products/Products.jsx";
import Clients from "views/Main/Clients/Clients.jsx";
import Agenda from "views/Main/Agenda/Agenda.jsx";
import Sellers from "views/Main/Sellers/Sellers.jsx";
import Events from "views/Main/Events/Events.jsx";
import Rentals from "views/Main/Rentals/Rentals.jsx";
import Users from "views/Main/Users/Users.jsx";

import Dashboard from "@material-ui/icons/Dashboard";
import Assessment from "@material-ui/icons/Assessment";
import Description from "@material-ui/icons/Description";
import Loyalty from "@material-ui/icons/Loyalty";
import LocalBar from "@material-ui/icons/LocalBar";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Event from "@material-ui/icons/Event";
import RecentActors from "@material-ui/icons/RecentActors";
import Settings from "@material-ui/icons/Settings";

import { roles } from "../utils/variables";

const appRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Material Dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: "/clients",
    sidebarName: "Clientes",
    navbarName: "Clientes",
    icon: RecentActors,
    component: Clients
  },
  {
    path: "/rentals",
    sidebarName: "Locações",
    navbarName: "Locações",
    icon: Description,
    component: Rentals,
  },
  {
    path: "/products",
    sidebarName: "Produtos",
    navbarName: "Produtos",
    icon: Loyalty,
    component: Products,
  },
  {
    path: "/events",
    sidebarName: "Eventos",
    navbarName: "Eventos",
    icon: LocalBar,
    component: Events
  },
  {
    path: "/finances",
    sidebarName: "Financeiro",
    navbarName: "Financeiro",
    icon: Assessment,
    component: Products,
    requiredRole: roles.Admin
  },
  {
    path: "/agenda",
    sidebarName: "Agenda",
    navbarName: "Agenda",
    icon: Event,
    component: Agenda
  },
  {
    path: "/sellers",
    sidebarName: "Vendedoras",
    navbarName: "Vendedoras",
    icon: AssignmentInd,
    component: Sellers,
    requiredRole: roles.Admin
  },
  {
    path: "/users",
    sidebarName: "Usuários",
    navbarName: "Usuários",
    icon: Settings,
    component: Users,
    requiredRole: roles.Admin
  },
  {
    redirect: true,
    path: "/",
    to: "/dashboard",
    navbarName: "Redirect"
  },
];

export default appRoutes;
