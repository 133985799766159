import { CALL_API } from '../utils/apiMiddleware'
import * as ActionTypes from './resources'

export const fetchAllEvents = () => ({
    [CALL_API]: {
        types: [ActionTypes.GET_ALL_REQUEST, ActionTypes.GET_ALL_SUCCESS, ActionTypes.GET_ALL_FAILURE],
        endpoint: `/events/`
    }
})

export const fetchEvent = (id) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.GET_SINGLE_REQUEST, ActionTypes.GET_SINGLE_SUCCESS, ActionTypes.GET_SINGLE_FAILURE],
            endpoint: `/events/${id}`
        }
    })
}

export const postEvent = (event) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.POST_REQUEST, ActionTypes.POST_SUCCESS, ActionTypes.POST_FAILURE],
            endpoint: `/events/`,
            method: 'POST',
            body: event
        }
    })
}

export const putEvent = (eventId, event) => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.PUT_REQUEST, ActionTypes.PUT_SUCCESS, ActionTypes.PUT_FAILURE],
            endpoint: `/events/${eventId}`,
            method: 'PUT',
            body: event
        }
    })
}

export const deleteEvent = eventId => {
    return ({
        [CALL_API]: {
            types: [ActionTypes.DELETE_REQUEST, ActionTypes.DELETE_SUCCESS, ActionTypes.DELETE_FAILURE],
            endpoint: `/events/${eventId}/`,
            method: 'DELETE'
        }
    })
}